import { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import styles from '../WelcomePage/login.module.css';
import { Music, UserCog, Megaphone, Check, X } from 'lucide-react';

const SpotifyCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { register, loginUser } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [artistProfileUrl, setArtistProfileUrl] = useState('');
  const [spotifyData, setSpotifyData] = useState(null);
  const [showRoleSelection, setShowRoleSelection] = useState(false);

  const roles = [
    {
      id: 'artist',
      title: 'Artist',
      description: 'I make music and perform',
      icon: Music
    },
    {
      id: 'manager',
      title: 'Manager',
      description: 'I represent artists',
      icon: UserCog
    },
    {
      id: 'promoter',
      title: 'Promoter',
      description: 'I organize events and book artists',
      icon: Megaphone
    }
  ];

  const handleRoleToggle = (roleId) => {
    setSelectedRoles(prev => {
      if (prev.includes(roleId)) {
        return prev.filter(id => id !== roleId);
      }
      return [...prev, roleId];
    });
    setError('');
  };

  const handleContinue = async () => {
    if (selectedRoles.length === 0) {
      setError('Please select at least one role');
      return;
    }

    try {
      // Debug logs
      console.log('Selected roles before registration:', selectedRoles);
      console.log('Spotify data:', spotifyData);
      
      const userData = await register(
        {
          ...spotifyData,
          roles: selectedRoles // Explicitly include roles in spotifyData
        },
        selectedRoles,
        selectedRoles.includes('artist') ? artistProfileUrl : null
      );

      console.log('Registration response:', userData);

      if (userData.roles?.includes('artist')) {
        navigate(`/profile?artist_name=${encodeURIComponent(userData.name)}`);
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Registration error details:', error);
      setError(error.message || 'Registration failed');
      setTimeout(() => navigate('/login'), 2000);
    }
  };

  useEffect(() => {
    const handleCallback = async () => {
      const params = new URLSearchParams(window.location.search);
      
      console.log('Starting callback handling with params:', Object.fromEntries(params));

      const error = params.get('error');
      const code = params.get('code');
      const state = params.get('state');

      if (error) {
        console.error('Auth Error:', error);
        setError(error);
        setTimeout(() => navigate('/login'), 2000);
        return;
      }

      if (code) {
        try {
          const apiUrl = process.env.REACT_APP_API;
          console.log('Making request to:', `${apiUrl}/callback`);
          
          const queryParams = new URLSearchParams({
            code,
            state,
            redirect_uri: window.location.origin + '/callback'
          });

          const response = await fetch(`${apiUrl}/callback?${queryParams}`, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Origin': window.location.origin
            },
            mode: 'cors',
            credentials: 'include'
          });

          if (!response.ok) {
            throw new Error(`Token exchange failed: ${response.status}`);
          }

          // Follow redirects manually if needed
          if (response.redirected) {
            console.log('Got redirect to:', response.url);
            window.location.href = response.url;
            return;
          }

          // Instead of redirecting, parse the URL and handle the data
          const redirectParams = new URLSearchParams(new URL(response.url).search);
          const spotifyId = redirectParams.get('spotify_id') || redirectParams.get('id');
          const accessToken = redirectParams.get('access_token');
          const refreshToken = redirectParams.get('refresh_token');
          const isNewUser = redirectParams.get('is_new_user') === '1';
          const displayName = redirectParams.get('display_name');
          const email = redirectParams.get('email');

          if (!spotifyId || !accessToken) {
            throw new Error('Missing required authentication data');
          }

          const loginData = {
            spotify_id: spotifyId,
            access_token: accessToken,
            refresh_token: refreshToken,
            email: email ? decodeURIComponent(email) : '',
            name: displayName || spotifyId,
            profile_image: redirectParams.get('profile_image')
          };

          console.log('Processing authentication with:', {
            ...loginData,
            access_token: '[REDACTED]',
            refresh_token: refreshToken ? '[PRESENT]' : '[ABSENT]'
          });

          if (isNewUser) {
            setSpotifyData(loginData);
            setShowRoleSelection(true);
          } else {
            const userData = await loginUser(loginData);
            if (userData.roles?.includes('artist')) {
              navigate(`/profile?artist_name=${encodeURIComponent(userData.name)}`);
            } else {
              navigate('/');
            }
          }
        } catch (error) {
          console.error('Code exchange error:', error);
          setError('Failed to exchange authorization code');
          setTimeout(() => navigate('/login'), 2000);
        }
        return;
      }

      // Handle non-code flows...
      console.error('No code or error in callback');
      setError('Invalid callback data');
      setTimeout(() => navigate('/login'), 2000);
    };

    handleCallback();
  }, [location, navigate, loginUser]);

  const ErrorDisplay = ({ error }) => (
    <div className={styles.error_container}>
      <div className={styles.error_card}>
        <div className={styles.error_icon}>
          <X size={32} />
        </div>
        <h2 className={styles.error_title}>Registration Failed</h2>
        <p className={styles.error_message}>
          {error === 'DUPLICATE_USER' ? 
            'An account with this email already exists.' :
            error === 'INVALID_SPOTIFY' ?
            'Could not verify Spotify credentials.' :
            error === 'MISSING_DATA' ?
            'Required information is missing.' :
            'Something went wrong during registration.'}
        </p>
        <p className={styles.error_detail}>{error}</p>
        <button 
          onClick={() => navigate('/login')} 
          className={styles.error_button}
        >
          Return to Login
        </button>
      </div>
    </div>
  );

  if (error) {
    return <ErrorDisplay error={error} />;
  }

  if (!showRoleSelection) {
    return (
      <div className={styles.loading_container}>
        Setting up your account...
      </div>
    );
  }

  return (
    <div className={styles.login__page}>
      <div className={styles.login__container}>
        <div className={styles.login__content}>
          <div className={`${styles.login__card} ${styles.login__card_roles}`}>
            <h2 className={styles.login__title}>Choose Your Roles</h2>
            <p className={styles.login__subtitle}>
              Select all roles that apply to you
            </p>

            <div className={styles.login__roles_grid}>
              {roles.map((role) => {
                const Icon = role.icon;
                const isSelected = selectedRoles.includes(role.id);
                return (
                  <button
                    key={role.id}
                    className={`${styles.login__role_card} ${
                      isSelected ? styles.login__role_card_selected : ''
                    }`}
                    onClick={() => handleRoleToggle(role.id)}
                  >
                    <div className={styles.login__role_icon_wrapper}>
                      <Icon size={32} className={styles.login__role_icon} />
                    </div>
                    <h3>{role.title}</h3>
                    <p>{role.description}</p>
                    <div className={`${styles.login__role_checkmark} ${
                      isSelected ? styles.login__role_checkmark_active : ''
                    }`}>
                      <Check size={20} />
                    </div>
                  </button>
                );
              })}
            </div>

            {selectedRoles.includes('artist') && (
              <div className={styles.login__input_wrapper}>
                <input
                  type="url"
                  placeholder="Your Spotify Artist Profile URL"
                  className={styles.login__input}
                  value={artistProfileUrl}
                  onChange={(e) => setArtistProfileUrl(e.target.value)}
                  required
                />
                <Music className={styles.login__input_icon} />
              </div>
            )}

            {error && <span className={styles.login__error}>{error}</span>}

            <div className={styles.login__action_container}>
              <button
                onClick={handleContinue}
                className={`${styles.login__button_primary}`}
                disabled={selectedRoles.length === 0}
              >
                Complete Registration
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpotifyCallback;
