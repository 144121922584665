import React, { useState, useEffect, useRef, useContext } from 'react';
import axios from 'axios';
import { MapPin, Users, LayoutDashboard } from 'lucide-react';
import '../src/css/app.css';
import TrialBar from './components/Trial/TrialBar.js';
import { TrialProvider, useTrial } from './contexts/TrialContext';
import { Routes, Route, useNavigate } from 'react-router-dom';
import UserProfile from './components/Navigation/UserProfile';
import RouteHandler, { trackSearch, trackInspire, PortalWrapper} from './utils/RouteHandler.js';
import { TrieSearch } from './utils/TrieSearch';
import { useDebounce } from './hooks/useDebounce';

// Component imports
import AboutPage from './about';
import KnowledgeGraphBackground from './utils/KnowledgeGraphBackground';
import IntroAnimation from './components/Intro/IntroAnimation.js';
import SpotifyCallback from './components/WelcomePage/SpotifyCallback.js';

// Asset imports
import Synspire_logo from './images/Synspire_logo.png';
import { getObfuscatedKeyWithTimestamp } from './services/policy_delta';
import { UserContext } from './contexts/UserContext';

// Constants
const SCREENS = {
  HOME: 'home',
  ABOUT: 'about',
  DASHBOARD: 'dashboard',
  VENUE_FINDER: 'venueFinder',
  AUDIENCE_FINDER: 'audienceFinder',
  LOGIN: 'login',
  REGISTER: 'register',
  STAY_TUNED: 'stayTuned',
};

const SEARCH_MODES = {
  DASHBOARD: 'dashboard',
  VENUE: 'venue',
  AUDIENCE: 'audience'
};

const MODES = [
  { id: SEARCH_MODES.DASHBOARD, icon: LayoutDashboard, label: 'Search Profiles' },
  { id: SEARCH_MODES.VENUE, icon: MapPin, label: 'Find Venues' },
  { id: SEARCH_MODES.AUDIENCE, icon: Users, label: 'Grow Audiences' }
];

function App() {
  const { hasSeenIntro, setHasSeenIntro } = useContext(UserContext);
  const [appState, setAppState] = useState({
    activeScreen: SCREENS.HOME,
    searchMode: SEARCH_MODES.DASHBOARD,
    searchQuery: '',  
    artistName: '',
    isTransitioning: false,
    showIntro: true,
    artistSuggestions: [],
    currentSuggestion: '',
    artistNamesList: [],
    featureHints: { venue: false, audience: false },
    auth: {
      isAuthenticated: false,
      useRole: null, 
      userData: null,
      showingWelcome: false 
    }
  });

  const [isTrialMode, setIsTrialMode] = useState(false);
  const [currentTrialArtist, setCurrentTrialArtist] = useState(null);
  const [searchTrie] = useState(() => new TrieSearch());
  const [isLoading, setIsLoading] = useState(false);
  const debouncedSearchQuery = useDebounce(appState.searchQuery, 300);
  const [suggestionsCache] = useState(() => new Map());
  const [lastFetchTimestamp, setLastFetchTimestamp] = useState(0);

  const handleTrialMode = (artistName) => {
    setIsTrialMode(true);
    setCurrentTrialArtist(artistName);
    
    setAppState(prev => ({
      ...prev,
      activeScreen: SCREENS.DASHBOARD,
      artistName: artistName,
      searchQuery: artistName,
      auth: {
        isAuthenticated: true,
        tryMode: true,
        userData: null
      }
    }));
    
  
    navigateToScreen(SCREENS.DASHBOARD, { artistName });
  };

  const handleExitTrial = () => {
    setIsTrialMode(false);
    setCurrentTrialArtist(null);
    

    setAppState(prev => ({
      ...prev,
      activeScreen: SCREENS.LOGIN,
      artistName: '',
      searchQuery: '',
      auth: {
        isAuthenticated: false,
        tryMode: false,
        userData: null
      }
    }));


    const url = new URL(window.location);
    url.searchParams.delete('artist_name');
    window.history.replaceState({}, '', url.toString());
  };

  useEffect(() => {
    const handlePopState = (event) => {
      const url = new URL(window.location.href);
      const artistName = url.searchParams.get('artist_name');
  

      if (url.pathname === '/' && !artistName && appState.auth.tryMode) {
        setIsTrialMode(false);
        setCurrentTrialArtist(null);
        setAppState(prev => ({
          ...prev,
          auth: {
            isAuthenticated: false,
            tryMode: false,
            userData: null
          }
        }));
      }
  
      let screen = SCREENS.HOME;
      if (url.pathname === '/') {
        screen = SCREENS.HOME;
      } else if (url.pathname === '/venues') {
        screen = SCREENS.VENUE_FINDER;
      } else if (url.pathname === '/audience') {
        screen = SCREENS.AUDIENCE_FINDER;
      } else if (url.pathname === '/profile') {
        screen = SCREENS.DASHBOARD;
      } else if (url.pathname === '/about') {
        screen = SCREENS.ABOUT;
      }

      
      if (url.pathname === '/login' && isTrialMode) {
        setIsTrialMode(false);
        setCurrentTrialArtist(null);
      }
  
      setAppState(prevState => {
        const newState = {
          ...prevState,
          activeScreen: screen,
          artistName: artistName || '',
          searchMode: prevState.searchMode,
          searchQuery: artistName || ''
        };
  
        console.log('New App State:', newState);
        return newState;
      });
    };
  
    window.addEventListener('popstate', handlePopState);
  
    return () => window.removeEventListener('popstate', handlePopState);
  }, [isTrialMode]);

  const handleSuggestionClick = (suggestion) => {
    const artistName = suggestion.name || suggestion;
    trackSearch(artistName, appState.searchMode, 'suggestion');

    const screenMap = {
      [SEARCH_MODES.VENUE]: 'venues',
      [SEARCH_MODES.AUDIENCE]: 'audience',
      [SEARCH_MODES.DASHBOARD]: 'profile'
    };

    const path = screenMap[appState.searchMode];
    navigate(`/${path}?artist_name=${encodeURIComponent(artistName)}`);
    
    setAppState(prev => ({
      ...prev,
      artistName: artistName,
      searchQuery: artistName
    }));
  };

  const handleInspire = () => {
    const randomIndex = Math.floor(Math.random() * appState.artistNamesList.length);
    const randomArtist = appState.artistNamesList[randomIndex];

    setAppState(prev => ({
      ...prev,
      searchQuery: randomArtist,
      currentSuggestion: ''
    }));

    searchInputRef.current?.focus();
    trackInspire();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Tab' && appState.currentSuggestion) {
      e.preventDefault();
      setAppState(prev => ({
        ...prev,
        searchQuery: prev.currentSuggestion,
        currentSuggestion: ''
      }));
    } else if (e.key === 'Enter') {
      handleSearch(e);
    }
  };


  const graphRef = useRef(null);
  const searchInputRef = useRef(null);


  useEffect(() => {
    document.title = "Synspire";
    const pathname = window.location.pathname;
    const params = new URLSearchParams(window.location.search);
    const artistName = params.get('artist_name');
    const storedUser = localStorage.getItem('userData');
    const storedToken = localStorage.getItem('token');
    

    if (storedUser && storedToken) {
      const userData = JSON.parse(storedUser);
      setAppState(prev => ({
        ...prev,
        auth: {
          isAuthenticated: true,
          userRole: userData.roles,
          userData: userData,
          showingWelcome: false
        }
      }));
    }
    

    if (artistName) {
      setAppState(prev => ({ 
        ...prev, 
        artistName,
        searchQuery: artistName 
      }));
      

      if (pathname.includes('venues')) {
        navigateToScreen(SCREENS.VENUE_FINDER, { artistName });
      } else if (pathname.includes('audience')) {
        navigateToScreen(SCREENS.AUDIENCE_FINDER, { artistName });
      } else if (pathname.includes('profile')) {
        navigateToScreen(SCREENS.DASHBOARD, { artistName });
      }
    }
  }, []);


  useEffect(() => {
    const handleResize = () => {
      if (searchInputRef.current) {
        const width = window.innerWidth;
        searchInputRef.current.placeholder = width <= 600 
          ? "Search artist..." 
          : width <= 1000 
            ? "Enter an artist name" 
            : "Looking for an artist? Enter their name here";
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  useEffect(() => {
    if (!graphRef.current) {
      graphRef.current = <KnowledgeGraphBackground />;
    }
  }, []);


  const fetchArtistNames = async () => {
    try {
      const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
      const response = await axios.get(`${process.env.REACT_APP_API}/api/artist/ids`, {
        headers: {
          'API-Key': obfuscatedKey,
          'timestamp': timestamp,
        }
      });
      setAppState(prev => ({
        ...prev,
        artistNamesList: response.data,
        artistSuggestions: response.data
      }));
    } catch (error) {
      console.error('Error fetching artist names:', error);
    }
  };

  useEffect(() => {
    fetchArtistNames();
  }, [appState.activeScreen]);

  const navigate = useNavigate();
  
  const navigateToScreen = (screen, params = {}) => {
    const artistNameParam = params.artistName ? `?artist_name=${encodeURIComponent(params.artistName)}` : '';
    
    switch(screen) {
      case SCREENS.VENUE_FINDER:
        navigate(`/venues${artistNameParam}`);
        break;
      case SCREENS.AUDIENCE_FINDER:
        navigate(`/audience${artistNameParam}`);
        break;
      case SCREENS.DASHBOARD:
        navigate(`/profile${artistNameParam}`);
        break;
      case SCREENS.ABOUT:
        navigate('/about');
        break;
      case SCREENS.LOGIN:
        navigate('/login');
        break;
      case SCREENS.STAY_TUNED:
        navigate('/stay-tuned');
        break;
      default:
        navigate('/');
        break;
    }
  
    setAppState(prev => ({
      ...prev,
      activeScreen: screen,
      artistName: params.artistName || '',
      searchQuery: params.artistName || '',
      searchMode: params.searchMode || prev.searchMode
    }));
  };

  const handleNavigation = (action) => {
    if (isTrialMode) {
      handleExitTrial();
      navigateToScreen(SCREENS.LOGIN);
      return;
    }
    
    if (action === 'home') {
      if (!appState.auth.isAuthenticated) {
        navigateToScreen(SCREENS.LOGIN);
      } else {
        navigateToScreen(SCREENS.HOME);
      }
    } else if (action === 'back') {
      window.history.back();
    }
  };

 
  const handleIntroComplete = () => {
    setAppState(prev => ({ ...prev, showIntro: false }));
    setTimeout(() => setAppState(prev => ({
      ...prev,
      featureHints: { ...prev.featureHints, venue: true }
    })), 500);
    setTimeout(() => setAppState(prev => ({
      ...prev,
      featureHints: { ...prev.featureHints, audience: true }
    })), 1000);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (appState.searchQuery.trim()) {
      trackSearch(appState.searchQuery, appState.searchMode, 'search');

      const screenMap = {
        [SEARCH_MODES.VENUE]: 'venues',
        [SEARCH_MODES.AUDIENCE]: 'audience',
        [SEARCH_MODES.DASHBOARD]: 'profile'
      };

      const path = screenMap[appState.searchMode];
      navigate(`/${path}?artist_name=${encodeURIComponent(appState.searchQuery)}`);
    }
  };

  const handleInputChange = (e) => {
    const query = e.target.value || '';
    setAppState(prev => ({
      ...prev,
      searchQuery: query
    }));
  };

  useEffect(() => {
    const fetchSuggestions = async () => {
      if (!debouncedSearchQuery || debouncedSearchQuery.length < 2) {
        setAppState(prev => ({
          ...prev,
          artistSuggestions: []
        }));
        return;
      }

      const cacheKey = debouncedSearchQuery.toLowerCase();
      const now = Date.now();
      
      setIsLoading(true);
      try {

        if (suggestionsCache.has(cacheKey) && 
            now - lastFetchTimestamp < 5 * 60 * 1000) {
          const cachedResults = suggestionsCache.get(cacheKey);
          setAppState(prev => ({
            ...prev,
            artistSuggestions: cachedResults
          }));
          setIsLoading(false);
          return;
        }

        const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
        const response = await axios.get(
          `${process.env.REACT_APP_API}/api/artist/autocomplete`, {
            params: {
              q: debouncedSearchQuery,
              limit: 10
            },
            headers: {
              'API-Key': obfuscatedKey,
              'timestamp': timestamp,
            }
          }
        );

        const suggestions = response.data;
        suggestionsCache.set(cacheKey, suggestions);
        setLastFetchTimestamp(now);
        
        setAppState(prev => ({
          ...prev,
          artistSuggestions: suggestions
        }));
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setAppState(prev => ({
          ...prev,
          artistSuggestions: []
        }));
      } finally {
        setIsLoading(false);
      }
    };

    fetchSuggestions();
  }, [debouncedSearchQuery]);

  const handleAuthentication = (userData) => {

    if (isTrialMode) {
      setIsTrialMode(false);
      setCurrentTrialArtist(null);
    }
  
  
    setAppState(prev => {
      if (prev.auth.isAuthenticated) {
        return prev;
      }
      return {
        ...prev,
        auth: {
          isAuthenticated: true,
          userRole: userData.roles,
          userData: userData,
          showingWelcome: false
        },
        activeScreen: SCREENS.HOME
      };
    });
  };

  const renderSuggestion = (suggestion) => (
    <div
      key={suggestion.id || suggestion}
      className="suggestion-item"
      onClick={() => handleSuggestionClick(suggestion)}
    >
      <div className="suggestion-main">
        <span className="suggestion-name">
          {suggestion.name || suggestion}
        </span>
      </div>
      <span className="suggestion-type">Artist</span>
    </div>
  );

  const renderSearchInput = () => (
    <div className="search-wrapper">
      <input
        ref={searchInputRef}
        type="text"
        placeholder="Looking for an artist? Enter their name here"
        className="search-bar"
        value={appState.searchQuery || ''}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
      />
      {appState.searchQuery.length >= 2 && (
        <div className="suggestions-container">
          {isLoading ? (
            <div className="suggestion-item loading">
              <div className="suggestion-loader"></div>
              Loading...
            </div>
          ) : appState.artistSuggestions?.length > 0 ? (
            appState.artistSuggestions.map(renderSuggestion)
          ) : (
            <div className="suggestion-item no-results">
              No matches found
            </div>
          )}
        </div>
      )}
    </div>
  );

  const renderSearchContent = () => {
    if (!appState.auth.isAuthenticated && !localStorage.getItem('token')) {
      navigateToScreen(SCREENS.LOGIN);
      return null;
    }

    return (
      <div className="contentclass">
        <div className="search-content fade-in">
          <img 
            src={Synspire_logo} 
            alt="Synspire Logo" 
            className="company_logo" 
          />
          <div className={`search-bar-container ${appState.featureHints.venue ? 'with-hints' : ''}`}>
            {renderSearchInput()}
            <div className="search-mode-slider">
              {MODES.map((mode) => {
                const Icon = mode.icon;
                const isActive = appState.searchMode === mode.id;
                return (
                  <button
                    key={mode.id}
                    onClick={() => setAppState(prev => ({
                      ...prev,
                      searchMode: mode.id
                    }))}
                    className={`mode-button ${isActive ? 'active' : ''}`}
                  >
                    <Icon className="mode-icon" />
                    <span className="mode-label">{mode.label}</span>
                  </button>
                );
              })}
              <div className="mode-slider-background"></div>
            </div>
            <div className="search-bottom-buttons">
              <button className="inspire-button" onClick={handleInspire}>
                Inspire me
              </button>
              <button className="search-button" onClick={handleSearch}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAboutContent = () => {
    return (
      <div className={"contentclass"} style={{ 
        width: '80vw',
        height: '70vh',
        maxWidth: '1200px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
      }}>
        <AboutPage />
      </div>
    );
  };

  return (
    <TrialProvider>
      {appState.showIntro && !hasSeenIntro && (
        <IntroAnimation 
          onComplete={() => {
            handleIntroComplete();
            setHasSeenIntro(true);
          }} 
        />
      )}
      {isTrialMode && (
        <TrialBar 
          currentArtist={currentTrialArtist}
          onExitTrial={handleExitTrial}
        />
      )}
      <div className={`app-container ${isTrialMode ? 'trial-mode' : ''}`}>
        <div className={`top-bar ${appState.showIntro ? 'hidden' : ''}`}>
          <div className="nav-buttons">
            <button className="button" onClick={() => navigateToScreen(SCREENS.HOME)}>
              Home
            </button>
            <button className="button" onClick={() => navigateToScreen(SCREENS.ABOUT)}>
              About
            </button>
          </div>
          {appState.auth.isAuthenticated && (
            <UserProfile 
              onSignOut={() => {
                setAppState(prev => ({
                  ...prev,
                  auth: {
                    isAuthenticated: false,
                    userRole: null,
                    userData: null,
                    showingWelcome: false
                  }
                }));
                navigateToScreen(SCREENS.LOGIN);
              }}
            />
          )}
        </div>

        {graphRef.current}

        <div className={`content ${appState.showIntro ? 'intro-active' : ''} ${appState.activeScreen === SCREENS.LOGIN ? 'login-active' : ''}`}>
          <Routes>
            <Route path="/about" element={renderAboutContent()} />
            <Route path="/callback" element={<SpotifyCallback />} />
            <Route path="*" element={
              <RouteHandler
                appState={appState}
                handleNavigation={handleNavigation}
                navigateToScreen={navigateToScreen}
                handleAuthentication={handleAuthentication}
                handleTrialMode={handleTrialMode}
                renderSearchContent={renderSearchContent}
              />
            } />
          </Routes>
        </div>    
      </div>
    </TrialProvider>
  );
}

export default App;

// Congratulations! If you are reading this then it means you are definitely a curious person.
// We hire curious people. If you are interested in working with us, please reach out to us at
// contact@synspireforartists.com