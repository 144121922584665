import { track } from '@vercel/analytics';
import React, { Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useLocation, useSearchParams } from 'react-router-dom';
import ArtistDashboard from '../components/dashboard/ArtistDashboard';
import VenueFinder from '../components/VenueFinder/VenueFinder';
import AudienceFinder from '../components/AudienceFinder/AudienceFinder';
import Login from '../components/WelcomePage/Login';
import AboutPage from '../about';
import StayTuned from '../components/StayTuned/StayTuned';


const trackPageView = (page) => {
  track('page_view', { pageview: page });
};

const trackSearch = (query, mode, method) => {
  track('search', { 
    search_query: query, 
    search_mode: mode, 
    method: method 
  });
};

const trackInspire = () => {
  track('page_view', { pageview: 'inspire' });
};

const PortalWrapper = ({ children }) => {
  const portalTarget = document.getElementById('portal-root');

  if (!portalTarget) return null;

  return ReactDOM.createPortal(
    <Suspense fallback={null}>
      <div className="portal-content">
        {children}
      </div>
    </Suspense>,
    portalTarget
  );
};

const RouteHandler = ({ 
  appState, 
  handleNavigation, 
  navigateToScreen, 
  handleAuthentication, 
  handleTrialMode,
  renderSearchContent 
}) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const artistName = searchParams.get('artist_name') || appState.artistName;


  useEffect(() => {
    const path = location.pathname.substring(1) || 'home';
    trackPageView(path);
  }, [location.pathname]);


  const NON_PORTAL_ROUTES = {
    '/login': () => (
      <div className="login-container">
        <Login 
          onLogin={handleAuthentication}
          onTryMode={handleTrialMode}
          initialView="initial"
        />
      </div>
    ),
    '/about': () => (
      <div className="contentclass" style={{ 
        width: '80vw',
        height: '70vh',
        maxWidth: '1200px',
        margin: 'auto',
        overflow: 'hidden',
        position: 'relative',
      }}>
        <AboutPage />
      </div>
    ),
    '/stay-tuned': () => (
      <div className="contentclass">
        <StayTuned />
      </div>
    ),
    '/': () => renderSearchContent()
  };

  const PORTAL_ROUTES = {
    '/profile': () => (
      <ArtistDashboard 
        artistName={artistName} 
        onBackToSearch={() => {
          handleNavigation('home');
          window.location.href = '/';
        }}
        onShowVenueGraph={() => navigateToScreen('venueFinder', { artistName })}
        onAudienceFinder={() => navigateToScreen('audienceFinder', { artistName })}
      />
    ),
    '/venues': () => (
      <VenueFinder 
        artistName={artistName}
        range={3} 
        onBack={() => handleNavigation('back')}
        onBackToDashboard={() => navigateToScreen('dashboard', { artistName })}
        onHome={() => {
          handleNavigation('home');
          window.location.href = '/'; 
        }}
      />
    ),
    '/audience': () => (
      <AudienceFinder 
        artistName={artistName}
        onBackToDashboard={() => navigateToScreen('dashboard', { artistName })}
        onBack={() => {
          handleNavigation('home');
          window.location.href = '/';  
        }}
      />
    )
  };

  
  if (!appState.auth.isAuthenticated && 
      !appState.auth.tryMode && 
      location.pathname !== '/login' && 
      location.pathname !== '/about' &&
      location.pathname !== '/stay-tuned') {
    return NON_PORTAL_ROUTES['/login']();
  }

  
  if (NON_PORTAL_ROUTES[location.pathname]) {
    return NON_PORTAL_ROUTES[location.pathname]();
  }


  if (PORTAL_ROUTES[location.pathname]) {
    return (
      <PortalWrapper>
        <div className="route-transition">
          {PORTAL_ROUTES[location.pathname]()}
        </div>
      </PortalWrapper>
    );
  }


  return NON_PORTAL_ROUTES['/']();
};


export { PortalWrapper, trackSearch, trackInspire };
export default RouteHandler;
