import React, { createContext, useState, useEffect } from 'react';

export const UserContext = createContext({
  user: null,
  setUser: () => {},
  loading: true,
  isAuthenticated: false,
  roles: [],
  spotify_id: null,
  logout: () => {},
  register: async () => {},
  initiateSpotifyAuth: async () => {},
  profilePicture: null,
  hasSeenIntro: false,
  setHasSeenIntro: () => {},
});


const isStorageAvailable = () => {
  try {
    const test = '__storage_test__';
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch(e) {
    return false;
  }
};

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profilePicture, setProfilePicture] = useState(null);
  const [hasSeenIntro, setHasSeenIntro] = useState(() => {
    if (isStorageAvailable()) {
      return localStorage.getItem('hasSeenIntro') === 'true';
    }
    return false;
  });

  const handleSuccessfulLogin = (userData) => {
    const profileImg = userData.profile_image || 
                      userData.profileImage || 
                      userData.images?.[0]?.url;
    const userWithImage = {
      ...userData,
      profileImage: profileImg,
      profile_image: profileImg,
      images: profileImg ? [{ url: profileImg }] : [],
      roles: userData.roles || ['user']
    };
    setUser(userWithImage);
    setProfilePicture(profileImg);
    
    if (isStorageAvailable()) {
      try {
        localStorage.setItem('userData', JSON.stringify(userWithImage));
        localStorage.setItem('profilePicture', profileImg);
        localStorage.setItem('token', userData.accessToken);
      } catch (error) {
        console.warn('Could not save user data to localStorage:', error);
      }
    }
  };

  useEffect(() => {
    if (!isStorageAvailable()) {
      setLoading(false);
      return;
    }

    try {
      const params = new URLSearchParams(window.location.search);
      const profileImage = params.get('profile_image');
      const userData = localStorage.getItem('userData');
      const token = localStorage.getItem('token');
      
      if (userData && token) {
        const parsedUser = JSON.parse(userData);
        if (profileImage && !parsedUser.profile_image) {
          parsedUser.profile_image = profileImage;
          localStorage.setItem('userData', JSON.stringify(parsedUser));
        }
        setUser(parsedUser);
        setProfilePicture(parsedUser.profile_image);
        

        const isNewLogin = params.get('access_token');
        if (!isNewLogin) {
          setHasSeenIntro(true);
        }
      }
    } catch (error) {
      console.warn('Error restoring user session:', error);
      if (isStorageAvailable()) {
        localStorage.removeItem('userData');
        localStorage.removeItem('token');
      }
    } finally {
      setLoading(false);
    }
  }, []);

  const register = async (spotifyData, roles, artistProfileUrl = null) => {
    try {
      if (!spotifyData?.id) {
        throw new Error('MISSING_DATA: Spotify ID is required');
      }

      const API_URL = process.env.REACT_APP_API || 'http://localhost:8000';
      console.log('Registering with:', { 
        spotifyData, 
        roles, 
        artistProfileUrl 
      });
      
      const response = await fetch(`${API_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: spotifyData.email,
          spotify_id: spotifyData.id,
          name: spotifyData.display_name,
          roles: roles, 
          artist_profile_url: artistProfileUrl,
          access_token: spotifyData.access_token,
          refresh_token: spotifyData.refresh_token
        }),
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.message || 'Registration failed');
      }

      const userWithImage = {
        ...data.user,
        profileImage: spotifyData.images?.[0]?.url || null,
        accessToken: spotifyData.access_token,
        roles: roles 
      };

      if (isStorageAvailable()) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('userData', JSON.stringify(userWithImage));
      }
      
      setUser(userWithImage);
      setProfilePicture(userWithImage.profileImage);
      return userWithImage;
    } catch (error) {
      console.error('Registration error:', error);
      throw error;
    }
  };

  const initiateSpotifyAuth = async (roles, artistProfileUrl = null) => {
    try {
      console.log('Initiating Spotify auth...', { roles, artistProfileUrl });
      
      const response = await fetch(`${process.env.REACT_APP_API}/auth/spotify/login`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });

      if (!response.ok) {
        throw new Error('Failed to get authorization URL');
      }

      const data = await response.json();
      console.log('Got auth URL:', data);
      
      localStorage.setItem('spotify_auth_state', data.state);
      localStorage.setItem('pending_roles', JSON.stringify(roles));
      if (artistProfileUrl) {
        localStorage.setItem('pending_artist_url', artistProfileUrl);
      }

      // Don't modify the redirect_uri, use it as-is from the server
      window.location.href = data.authorizeUrl;
    } catch (error) {
      console.error('Failed to initiate Spotify auth:', error);
    }
  };

  const logout = () => {
    if (!user) return;


    setUser(null);
    setProfilePicture(null);
    setLoading(false);


    if (isStorageAvailable()) {
      try {
        localStorage.clear();
      } catch (error) {
        console.warn('Could not clear localStorage:', error);
      }
    }


    window.location.replace('/login');
  };

  const updateAuthState = (userData) => {
    if (!userData) {
      setUser(null);
      setProfilePicture(null);
      if (isStorageAvailable()) {
        try {
          localStorage.clear();
        } catch (error) {
          console.warn('Could not clear localStorage:', error);
        }
      }
      return;
    }

  
    const profileImg = userData.profile_image || 
                      userData.profileImage || 
                      userData.images?.[0]?.url;
    const updatedUser = {
      ...userData,
      profileImage: profileImg,
      profile_image: profileImg,
      images: profileImg ? [{ url: profileImg }] : []
    };
    
    setUser(updatedUser);
    setProfilePicture(profileImg);
    
    if (isStorageAvailable()) {
      try {
        localStorage.setItem('userData', JSON.stringify(updatedUser));
        localStorage.setItem('profilePicture', profileImg);
        localStorage.setItem('token', userData.accessToken);
      } catch (error) {
        console.warn('Could not save user data to localStorage:', error);
      }
    }
  };

  const loginUser = async (spotifyData) => {
    try {
      const API_URL = process.env.REACT_APP_API || 'http://localhost:8000';
      console.log('Logging in with Spotify data:', spotifyData);
      
      const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          spotify_id: spotifyData.id,
          access_token: spotifyData.access_token,
          refresh_token: spotifyData.refresh_token
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        if (response.status === 404) {
          return await register(spotifyData, ['user']);
        }
        throw new Error(errorData.message || 'Login failed');
      }

      const data = await response.json();
      const userWithImage = {
        ...data.user,
        profileImage: spotifyData.images?.[0]?.url || null,
        accessToken: spotifyData.access_token,
        roles: data.user.roles || ['user']
      };

      if (isStorageAvailable()) {
        localStorage.setItem('token', data.token);
        localStorage.setItem('userData', JSON.stringify(userWithImage));
        localStorage.setItem('spotify_token', spotifyData.access_token);
      }
      
      setUser(userWithImage);
      setProfilePicture(userWithImage.profileImage);
      return userWithImage;
    } catch (error) {
      console.error('Login error details:', error);
      throw error;
    }
  };

  const handleSetHasSeenIntro = (value) => {
    setHasSeenIntro(value);
    if (isStorageAvailable()) {
      localStorage.setItem('hasSeenIntro', value);
    }
  };

  return (
    <UserContext.Provider value={{ 
      user, 
      setUser: updateAuthState,
      loading,
      isAuthenticated: !!user,
      roles: user?.roles || [],
      spotify_id: user?.spotify_id,
      logout, 
      register, 
      loginUser,
      initiateSpotifyAuth,
      profilePicture,
      setProfilePicture,
      hasSeenIntro,
      setHasSeenIntro: handleSetHasSeenIntro,
    }}>
      {children}
    </UserContext.Provider>
  );
};
