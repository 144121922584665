import React, { useEffect, useState, useRef } from 'react';
import styles from './ArtistDashboard.module.css';
import { getObfuscatedKeyWithTimestamp } from '../../services/policy_delta';
import { House, Share, MapPin, Users, Map, MoreHorizontal, ChevronDown } from 'lucide-react';
import axios from 'axios';
import { fetchSpotifyData } from './SpotifyWrapper';
import { FastAverageColor } from 'fast-average-color';
import BackgroundCanvas from './BackgroundCanvas';


function ArtistDashboard({ artistName, onBackToSearch, onShowVenueGraph, onAudienceFinder }) {
  const [artistData, setArtistData] = useState(null);
  const [spotifyData, setSpotifyData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedSection, setExpandedSection] = useState(null);
  const [leftPanelExpanded, setLeftPanelExpanded] = useState(false);
  const [scrollArrowVisible, setScrollArrowVisible] = useState(true);
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const rightContentRef = useRef(null);
  const [dragStartY, setDragStartY] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [dominantColor, setDominantColor] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [topTracks, setTopTracks] = useState([]);
  const [followers, setFollowers] = useState(0);
  const [popularity, setPopularity] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);
  const sections = ['USP', 'Talents', 'Values', 'Tracks', 'Audience'];
  const sectionRefs = useRef([]);

  useEffect(() => {
    if (window.innerWidth <= 768) {
      setIsMobile(true);
      

      const sections = document.querySelectorAll(`.${styles.mobileSection}`);
      sections.forEach(section => {
        section.style.opacity = '1';
        section.style.visibility = 'visible';
        section.style.transform = 'none';
      });
    }
  }, []); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
        const artistResponse = await axios.get(`${process.env.REACT_APP_API}/artist/profile?artist_name=${encodeURIComponent(artistName)}`, {
          headers: {
            'API-Key': obfuscatedKey,
            'timestamp': timestamp,
          }
        });
        
        const data = artistResponse.data;
        data.name = data.name.charAt(0).toUpperCase() + data.name.slice(1);
        setArtistData(data);


        if (data.spotify_id) {
          const spotifyResponse = await fetchSpotifyData(data.spotify_id);
          setSpotifyData(spotifyResponse);
          if (spotifyResponse) {
            setTopTracks(spotifyResponse.topTracks);
            setFollowers(spotifyResponse.followers);
            setPopularity(spotifyResponse.popularity);
            

            if (spotifyResponse.genres?.length > 0) {
              data.genre = [...new Set([...data.genre, ...spotifyResponse.genres])];
            }
          }
        }
        
          setArtistData(data);
          setIsLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [artistName]);

  useEffect(() => {
    if (spotifyData?.pfp) {
      const fac = new FastAverageColor();
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = spotifyData.pfp;
      
      img.onload = async () => {
        const targetHueRanges = [
          { min: 0, max: 30, name: 'red' },      // Reds
          { min: 30, max: 60, name: 'orange' },   // Oranges
          { min: 200, max: 240, name: 'blue' },   // Blues
          { min: 270, max: 310, name: 'purple' }, // Purples
          { min: 45, max: 65, name: 'yellow' },   // Yellows
          { min: 120, max: 150, name: 'green' }   // Greens
        ];

        // Sample more regions including edges and corners where logos/text often appear
        const regions = [
          { left: 0, top: 0, width: img.width, height: img.height },         // Full image
          { left: 0, top: 0, width: img.width/3, height: img.height/3 },      // Top left
          { left: img.width*2/3, top: 0, width: img.width/3, height: img.height/3 }, // Top right
          { left: 0, top: img.height*2/3, width: img.width/3, height: img.height/3 }, // Bottom left
          { left: img.width*2/3, top: img.height*2/3, width: img.width/3, height: img.height/3 }, // Bottom right
          { left: img.width/3, top: img.height/3, width: img.width/3, height: img.height/3 }, // Center
          { left: 0, top: img.height/3, width: img.width/3, height: img.height/3 }, // Left center
          { left: img.width*2/3, top: img.height/3, width: img.width/3, height: img.height/3 }, // Right center
        ];

        const colors = await Promise.all(regions.map(region => 
          fac.getColorAsync(img, {
            left: region.left,
            top: region.top,
            width: region.width,
            height: region.height,
            algorithm: 'dominant',
            ignoredColor: [
              [255, 255, 255, 255],
              [0, 0, 0, 255],       
              [128, 128, 128, 255] 
            ]
          })
        ));


        const colorScores = colors.map(color => {
          const [h, s, l] = rgbToHsl(color.value[0], color.value[1], color.value[2]);
          let score = 0;


          score += s * 2;


          const inTargetRange = targetHueRanges.some(range => 
            (h >= range.min && h <= range.max)
          );
          if (inTargetRange) score += 50;


          const lightnessScore = l >= 35 && l <= 65 ? 30 : 
                                l > 65 ? Math.max(0, 30 - (l - 65)) : 
                                Math.max(0, 30 - (35 - l));
          score += lightnessScore;


          if (s < 40) score -= 100;
          if (l < 20 || l > 80) score -= 50;
          

          if (s < 15) score -= 200;

          return {
            color: color.value,
            score,
            hsl: [h, s, l]
          };
        });

        const topColors = colorScores
          .sort((a, b) => b.score - a.score)
          .slice(0, 3)
          .filter(color => color.score > 0);

       
        const bestColor = topColors.length > 0 ? topColors[0] : {
          color: [65, 105, 225], 
          hsl: [225, 73, 57]
        };

        const [h, s, l] = bestColor.hsl;

        const saturationBoost = 1.3;
        const adjustedSaturation = Math.min(100, s * saturationBoost);

        
        const complementaryHue = (h + 180) % 360;
        const [complementaryR, complementaryG, complementaryB] = hslToRgb(
          complementaryHue / 360,
          Math.min(100, adjustedSaturation * 1.2) / 100, 
          l / 100
        );


        const analogous1Hue = (h + 30) % 360;
        const analogous2Hue = (h - 30 + 360) % 360;
        const [analogous1R, analogous1G, analogous1B] = hslToRgb(
          analogous1Hue / 360,
          Math.min(100, adjustedSaturation * 1.1) / 100,
          l / 100
        );
        const [analogous2R, analogous2G, analogous2B] = hslToRgb(
          analogous2Hue / 360,
          Math.min(100, adjustedSaturation * 1.1) / 100,
          l / 100
        );


        const triadic1Hue = (h + 120) % 360;
        const triadic2Hue = (h + 240) % 360;
        const [triadic1R, triadic1G, triadic1B] = hslToRgb(
          triadic1Hue / 360,
          Math.min(100, adjustedSaturation * 1.15) / 100,
          l / 100
        );
        const [triadic2R, triadic2G, triadic2B] = hslToRgb(
          triadic2Hue / 360,
          Math.min(100, adjustedSaturation * 1.15) / 100,
          l / 100
        );

        const textLight = hslToRgb(h / 360, 0.1, 0.95); 
        const textDark = hslToRgb(h / 360, 0.8, 0.15);  

        
        const root = document.documentElement;
        root.style.setProperty('--primary-color', 
          `rgb(${bestColor.color[0]}, ${bestColor.color[1]}, ${bestColor.color[2]})`
        );
        root.style.setProperty('--complementary-color',
          `rgb(${complementaryR}, ${complementaryG}, ${complementaryB})`
        );
        root.style.setProperty('--analogous-1', `rgb(${analogous1R}, ${analogous1G}, ${analogous1B})`);
        root.style.setProperty('--analogous-2', `rgb(${analogous2R}, ${analogous2G}, ${analogous2B})`);
        root.style.setProperty('--triadic-1', `rgb(${triadic1R}, ${triadic1G}, ${triadic1B})`);
        root.style.setProperty('--triadic-2', `rgb(${triadic2R}, ${triadic2G}, ${triadic2B})`);
        root.style.setProperty('--text-light', `rgb(${textLight[0]}, ${textLight[1]}, ${textLight[2]})`);
        root.style.setProperty('--text-dark', `rgb(${textDark[0]}, ${textDark[1]}, ${textDark[2]})`);
        
        // Add RGB variables for gradient transparency
        root.style.setProperty('--primary-rgb', `${bestColor.color[0]}, ${bestColor.color[1]}, ${bestColor.color[2]}`);
        root.style.setProperty('--complementary-rgb', `${complementaryR}, ${complementaryG}, ${complementaryB}`);
        root.style.setProperty('--analogous-1-rgb', `${analogous1R}, ${analogous1G}, ${analogous1B}`);
        root.style.setProperty('--analogous-2-rgb', `${analogous2R}, ${analogous2G}, ${analogous2B}`);
        
        // Set gradients
        root.style.setProperty('--primary-gradient', 
          `linear-gradient(135deg, rgb(${bestColor.color[0]}, ${bestColor.color[1]}, ${bestColor.color[2]}) 0%, 
          rgb(${analogous1R}, ${analogous1G}, ${analogous1B}) 100%)`
        );
        root.style.setProperty('--accent-gradient',
          `linear-gradient(135deg, rgb(${complementaryR}, ${complementaryG}, ${complementaryB}) 0%,
          rgb(${triadic1R}, ${triadic1G}, ${triadic1B}) 100%)`
        );

        
        root.style.setProperty('--dashboard-bg', 
          `linear-gradient(135deg, 
            rgba(${analogous1R}, ${analogous1G}, ${analogous1B}, 0.1) 0%,
            rgba(${analogous2R}, ${analogous2G}, ${analogous2B}, 0.05) 100%
          )`
        );

        
        const brightness = (bestColor.color[0] * 299 + bestColor.color[1] * 587 + bestColor.color[2] * 114) / 1000;
        const isBackgroundDark = brightness < 128;

        
        const textColor = isBackgroundDark ? 
          'rgb(255, 255, 255)' : 
          'rgb(33, 33, 33)';     

       
        root.style.setProperty('--left-panel-text', textColor);
        root.style.setProperty('--left-panel-text-secondary', 
          isBackgroundDark ? 'rgba(255, 255, 255, 0.8)' : 'rgba(33, 33, 33, 0.8)'
        );

        setDominantColor({
          value: bestColor.color,
          isDark: l < 50,
          complementary: [complementaryR, complementaryG, complementaryB],
          analogous: [
            [analogous1R, analogous1G, analogous1B],
            [analogous2R, analogous2G, analogous2B]
          ],
          triadic: [
            [triadic1R, triadic1G, triadic1B],
            [triadic2R, triadic2G, triadic2B]
          ],
          hsl: [h, s, l]
        });
      };
    }
  }, [spotifyData]);

  
  const hslToRgb = (h, s, l) => {
    let r, g, b;
  
    if (s === 0) {
      r = g = b = l;
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1/6) return p + (q - p) * 6 * t;
        if (t < 1/2) return q;
        if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
        return p;
      };
  
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1/3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1/3);
    }
  
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  const rgbToHsl = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;

    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
        default: h = 0;
      }
      h /= 6;
    }

    return [h * 360, s * 100, l * 100];
  };



  
  useEffect(() => {
    const handleResize = () => {
      const isMobileView = window.innerWidth <= 768;
      setIsMobile(isMobileView);
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (rightContentRef.current && !hasScrolled && isMobile) {
        const scrollPosition = rightContentRef.current.scrollTop;
        if (scrollPosition > 0) {
          setScrollArrowVisible(false);
          setHasScrolled(true);
        }
      }
    };

    const rightContent = rightContentRef.current;
    if (rightContent) {
      rightContent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (rightContent) {
        rightContent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasScrolled, isMobile]);

  const handleScroll = (e) => {
    const sections = document.querySelectorAll(`.${styles.section}`);
    const scrollPosition = window.scrollY;

    sections.forEach(section => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.offsetHeight;

      if (scrollPosition >= sectionTop - window.innerHeight / 2 &&
          scrollPosition < sectionTop + sectionHeight - window.innerHeight / 2) {
        section.style.transform = 'scale(1)';
        section.style.opacity = '1';
      } else {
        section.style.transform = 'scale(0.98)';
        section.style.opacity = '0.8';
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  useEffect(() => {
    const scrollContainers = document.querySelectorAll(`.${styles.scrollableCards}`);
    scrollContainers.forEach(container => {
      let isDown = false;
      let startX;
      let scrollLeft;

      container.addEventListener('mousedown', (e) => {
        isDown = true;
        startX = e.pageX - container.offsetLeft;
        scrollLeft = container.scrollLeft;
      });

      container.addEventListener('mouseleave', () => {
        isDown = false;
      });

      container.addEventListener('mouseup', () => {
        isDown = false;
      });

      container.addEventListener('mousemove', (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - container.offsetLeft;
        const walk = (x - startX) * 2;
        container.scrollLeft = scrollLeft - walk;
      });
    });
  }, []);

  useEffect(() => {
    if (!isMobile) return;

    const handleScroll = () => {
      const container = rightContentRef.current;
      if (!container) return;

      const sectionElements = container.querySelectorAll(`.${styles.mobileSection}`);
      let current = 0;

      sectionElements.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= window.innerHeight / 2) {
          current = index;
          section.classList.add(styles.active);
        } else {
          section.classList.remove(styles.active);
        }
      });

      setCurrentSection(current);
    };

    const container = rightContentRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isMobile]);


  useEffect(() => {
    return () => {
      const elements = document.querySelectorAll(`.${styles.mobileSection}, .${styles.coreValuesGrid}, .${styles.tracksListContainer}`);
      elements.forEach(el => {
        if (el) {
          el.style.transform = 'none';
          el.style.opacity = '1';
          el.style.transition = 'none';
        }
      });
    };
  }, []);

  const scrollToSection = (index) => {
    sectionRefs.current[index]?.scrollIntoView({ behavior: 'smooth' });
  };

  if (isLoading) {
    return (
      <div className='content'>
        <div className={styles.loadingSpinner}>
          <div className={styles.spinner}></div>
          <p>Loading artist data...</p>
        </div>
      </div>
    );
  }

  if (!artistData || !artistData.name) {
    setTimeout(() => {
      onBackToSearch();
    }, 3000);
    
    return (
        <div className='content'>
        <div className={styles.notFoundOverlay}>
          <div className={styles.notFoundContent}>
            <div className={styles.notFoundIcon}>🎵</div>
            <h2>Artist Not Found</h2>
            <p>We couldn't find any data for this artist</p>
            <div className={styles.redirectMessage}>
              Redirecting back to search...
              <div className={styles.loadingDots}>
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </div>
            </div>
          </div>
        </div>
        </div>
      
      );
  }

  const handleButtonClick = (event) => {
    event.stopPropagation();
  };

  const handleShare = () => {
    if (navigator.share) {
      const url = new URL(window.location.href);
      url.search = '';  
      const cleanUrl = url.toString();
      navigator.share({
        title: `I found an artist for you!`,
        text: `Check out ${artistData.name} on the Synspire!`,
        url: `${cleanUrl}?artist_name=${encodeURIComponent(artistData.name)}`,
      }).catch((error) => console.error('Error sharing:', error));
    } else {
      console.warn('Web Share API not supported in this browser.');
    }
  };

  const toggleExpand = (section) => {
    setExpandedSection(prevSection => prevSection === section ? null : section);
  };

  const truncateDescription = (text, maxLength = 500) => {
    if (text.length <= maxLength) return text;
    return text.substr(0, maxLength) + '...';
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      toggleExpand(null);
    }
  };



  const ExpandableSection = ({ title, content }) => (
    <div className={styles.expandableSection}>
      <h2>{title}</h2>
      <div className={styles.expandableContent}>{content}</div>
    </div>
  );


  const renderRightContent = () => {
    if (!isMobile) {
      return (
        <div className={styles.rightContentScroll} ref={rightContentRef}>
          <div className={styles.topRow}>
            <div className={styles.uspRow}>
            <ExpandableSection
                title="Unique Selling Points"
                content={
                  <ul>
                    {Object.entries(artistData.usp).map(([key, value]) => (
                      <li key={key}>
                        <strong>{key}:</strong> {value}
                      </li>
                    ))}
                  </ul>
                }
              />
              {scrollArrowVisible && isMobile && (
                <div className={styles.scrollArrow}>
                  <ChevronDown size={24} />
                </div>
              )}
            </div>
            <div className={styles.keyTalentsRow}>
              <ExpandableSection
                title="Key Talents"
                content={
                  <ul>
                    {Object.entries(artistData.key_talents).map(([key, value]) => (
                      <li key={key}>
                        <strong>{key}:</strong> {value}
                      </li>
                    ))}
                  </ul>
                }
              />
            </div>
          </div>
          <div className={styles.middleRow}>
            <h2>Core Values</h2>
            <div className={styles.coreValuesGrid}>
              {Object.entries(artistData.core_values).map(([key, value], index) => (
                <div key={index} className={styles.card}>
                  <h3>{key}</h3>
                  <p>{value.charAt(0).toUpperCase() + value.slice(1)}</p>
                </div>
              ))}
            </div>
          </div>

          {topTracks.length > 0 && (
            <>
              <h2>Top Tracks</h2>
              <div className={styles.section}>
                <div className={styles.tracksListContainer}>
                  <ol className={styles.tracksList}>
                    {topTracks.slice(0, Math.ceil(topTracks.length/2)).map((track, index) => (
                      <li key={`first-half-${track.id || index}`} 
                          className={styles.trackItem}
                          onClick={() => track.external_urls && window.open(track.external_urls.spotify, '_blank')}>
                        <div className={styles.trackItemContent}>
                          <div className={styles.trackMainInfo}>
                            <span className={styles.trackNumber}>{String(index + 1).padStart(2, '0')}</span>
                            <div className={styles.trackTitles}>
                              <h3>{track.name}</h3>
                              <p>{track.album?.name}</p>
                            </div>
                          </div>
                          <div className={styles.trackMetadata}>
                            <span>{track.popularity || 0}%</span>
                            <span>{track.album?.release_date ? new Date(track.album.release_date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            }) : 'N/A'}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                  <ol className={styles.tracksList} start={Math.ceil(topTracks.length/2) + 1}>
                    {topTracks.slice(Math.ceil(topTracks.length/2)).map((track, index) => (
                      <li key={`second-half-${track.id || index}`} 
                          className={styles.trackItem}
                          onClick={() => track.external_urls && window.open(track.external_urls.spotify, '_blank')}>
                        <div className={styles.trackItemContent}>
                          <div className={styles.trackMainInfo}>
                            <span className={styles.trackNumber}>
                              {String(index + Math.ceil(topTracks.length/2) + 1).padStart(2, '0')}
                            </span>
                            <div className={styles.trackTitles}>
                              <h3>{track.name}</h3>
                              <p>{track.album?.name}</p>
                            </div>
                          </div>
                          <div className={styles.trackMetadata}>
                            <span>{track.popularity || 0}%</span>
                            <span>{track.album?.release_date ? new Date(track.album.release_date).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric'
                            }) : 'N/A'}</span>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                </div>
              </div>
            </>
          )}

          <div className={styles.bottomRow}>
            <h2>Current Audience</h2>
            <div className={styles.scrollableCards}>
              {artistData.target_audience.map((audience, index) => (
                <div key={index} className={styles.card}>
                  <h3>{audience.audience}</h3>
                  <p>Age: {audience.age}</p>
                  <p><strong>Interests:</strong> {audience.interest.join(', ')}</p>
                  <p><strong>Values:</strong> {audience.values.join(', ')}</p>
                </div>
              ))}
            </div>

        {expandedSection && (
          <div className={styles.overlay} onClick={handleOverlayClick}>
            <div className={styles.overlayContent} onClick={(e) => e.stopPropagation()}>
              <h2>{expandedSection}</h2>
              {expandedSection === 'Unique Selling Points' ? (
                <ul>
                  {Object.entries(artistData.usp).map(([key, value]) => (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                  ))}
                </ul>
              ) : (
                <ul>
                  {Object.entries(artistData.key_talents).map(([key, value]) => (
                    <li key={key}>
                      <strong>{key}:</strong> {value}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        )}

          </div>
        </div>
      );
    }

    const mobileStyles = {
      opacity: 1,
      visibility: 'visible',
      transform: 'none',
      transition: 'none'
    };

    return (
      <div className={styles.rightContentScroll} ref={rightContentRef} style={mobileStyles}>
        <div className={styles.sectionIndicator}>
          {sections.map((_, index) => (
            <div
              key={index}
              className={`${styles.sectionDot} ${currentSection === index ? styles.active : ''}`}
              onClick={() => scrollToSection(index)}
            />
          ))}
        </div>

        <div className={styles.mobileSection} ref={el => sectionRefs.current[0] = el} style={mobileStyles}>
          <h2 className={styles.sectionTitle}>Unique Selling Points</h2>
          <div className={styles.expandableSection}>
            <div className={styles.expandableContent}>
            <ul>
                {Object.entries(artistData.usp).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>         
            </div>
          </div>
          <div className={styles.scrollPrompt}>
            <ChevronDown size={24} />
          </div>
        </div>

        <div className={styles.mobileSection} ref={el => sectionRefs.current[1] = el} style={mobileStyles}>
          <h2 className={styles.sectionTitle}>Key Talents</h2>
          <div className={styles.expandableSection}>
            <div className={styles.expandableContent}>
              <ul>
                {Object.entries(artistData.key_talents).map(([key, value]) => (
                  <li key={key}>
                    <strong>{key}:</strong> {value}
                  </li>
                ))}
              </ul>
          </div>
          </div>
          <div className={styles.scrollPrompt}>
            <ChevronDown size={24} />
          </div>
        </div>

        <div className={styles.mobileSection} ref={el => sectionRefs.current[2] = el} style={mobileStyles}>
          <h2 className={styles.sectionTitle}>Core Values</h2>
          <div className={styles.coreValuesGrid}>
            {Object.entries(artistData.core_values).map(([key, value], index) => (
              <div key={index} className={styles.card}>
                <h3>{key}</h3>
                <p>{value.charAt(0).toUpperCase() + value.slice(1)}</p>
              </div>
            ))}
          </div>
          <div className={styles.scrollPrompt}>
            <ChevronDown size={24} />
          </div>
        </div>

        {topTracks.length > 0 && (
          <div className={styles.mobileSection} ref={el => sectionRefs.current[3] = el} style={mobileStyles}>
            <h2 className={styles.sectionTitle}>Top Tracks</h2>
            <div className={styles.tracksListContainer}>
              <ol className={styles.tracksList}>
                {topTracks.slice(0, Math.ceil(topTracks.length/2)).map((track, index) => (
                  <li key={`first-half-${track.id || index}`} 
                      className={styles.trackItem}
                      onClick={() => track.external_urls && window.open(track.external_urls.spotify, '_blank')}>
                    <div className={styles.trackItemContent}>
                      <div className={styles.trackMainInfo}>
                        <span className={styles.trackNumber}>{String(index + 1).padStart(2, '0')}</span>
                        <div className={styles.trackTitles}>
                          <h3>{track.name}</h3>
                          <p>{track.album?.name}</p>
                        </div>
                      </div>
                      <div className={styles.trackMetadata}>
                        <span>{track.popularity || 0}%</span>
                        <span>{track.album?.release_date ? new Date(track.album.release_date).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        }) : 'N/A'}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
              <ol className={styles.tracksList} start={Math.ceil(topTracks.length/2) + 1}>
                {topTracks.slice(Math.ceil(topTracks.length/2)).map((track, index) => (
                  <li key={`second-half-${track.id || index}`} 
                      className={styles.trackItem}
                      onClick={() => track.external_urls && window.open(track.external_urls.spotify, '_blank')}>
                    <div className={styles.trackItemContent}>
                      <div className={styles.trackMainInfo}>
                        <span className={styles.trackNumber}>
                          {String(index + Math.ceil(topTracks.length/2) + 1).padStart(2, '0')}
                        </span>
                        <div className={styles.trackTitles}>
                          <h3>{track.name}</h3>
                          <p>{track.album?.name}</p>
                        </div>
                      </div>
                      <div className={styles.trackMetadata}>
                        <span>{track.popularity || 0}%</span>
                        <span>{track.album?.release_date ? new Date(track.album.release_date).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric'
                        }) : 'N/A'}</span>
                      </div>
                    </div>
                  </li>
                ))}
              </ol>
            </div>
            <div className={styles.scrollPrompt}>
              <ChevronDown size={24} />
            </div>
          </div>
        )}

        <div className={styles.mobileSection} ref={el => sectionRefs.current[4] = el} style={mobileStyles}>
          <h2 className={styles.sectionTitle}>Current Audience</h2>
          <div className={styles.scrollableCards}>
            {artistData.target_audience.map((audience, index) => (
              <div key={index} className={styles.card}>
                <h3>{audience.audience}</h3>
                <p>Age: {audience.age}</p>
                <p><strong>Interests:</strong> {audience.interest.join(', ')}</p>
                <p><strong>Values:</strong> {audience.values.join(', ')}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };


  const handleBioClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

 
  const handlePanelTouch = (e) => {
    if (!isMobile) return;
    setDragStartY(e.touches[0].clientY);
    setIsDragging(true);
  };

  const handlePanelTouchMove = (e) => {
    if (!isMobile || !isDragging) return;
    const touchY = e.touches[0].clientY;
    const deltaY = dragStartY - touchY;
    
    if (Math.abs(deltaY) > 20) {
      setLeftPanelExpanded(deltaY > 0);
      setIsDragging(false);
    }
  };

  const handlePanelTouchEnd = () => {
    if (!isMobile) return;
    setIsDragging(false);
  };

  const handlePanelClick = (e) => {
    if (e.target.classList.contains(styles.truncatedBio) || 
        e.target.classList.contains(styles.expandedBio)) {
      return;
    }
    
    if (isMobile) {
      setLeftPanelExpanded(!leftPanelExpanded);
    }
  };

  const getStatusFromPopularity = (score) => {
    if (score >= 95) return {
      level: 'CHART-TOPPING',
      icon: '🏆',
      supportIcon: '✨'
    };
    if (score >= 85) return {
      level: 'TRENDING',
      icon: '📈',
      supportIcon: '🌟'
    };
    if (score >= 75) return {
      level: 'MAINSTREAM',
      icon: '🔥',
      supportIcon: '💫'
    };
    if (score >= 60) return {
      level: 'NOTABLE',
      icon: '📈',
      supportIcon: '✨'
    };
    if (score >= 45) return {
      level: 'FEATURED',
      icon: '💫',
      supportIcon: '🎵'
    };
    if (score >= 30) return {
      level: 'ESTABLISHED',
      icon: '🎸',
      supportIcon: '🎼'
    };
    if (score >= 15) return {
      level: 'HIDDEN GEM',
      icon: '💎',
      supportIcon: '✨'
    };
    return {
      level: 'UNDERGROUND',
      icon: '⚡',
      supportIcon: '💫'
    };
  };

  const PopularityIndicator = ({ score }) => {
    const status = getStatusFromPopularity(score);
    const levels = [
      'UNDERGROUND', 'HIDDEN GEM', 'ESTABLISHED', 
      'FEATURED', 'NOTABLE', 'MAINSTREAM', 
      'TRENDING', 'CHART-TOPPING'
    ];
    const currentLevelIndex = levels.indexOf(status.level);
    
    return (
      <div className={styles.popularityStatus}>
        <div className={styles.statusIconWrapper}>
          <span className={styles.mainIcon}>{status.icon}</span>
          <span className={styles.supportIcon}>{status.supportIcon}</span>
        </div>
        <div className={styles.statusText}>{status.level}</div>
        <div className={styles.levelProgress}>
          {levels.map((_, index) => (
            <div 
              key={index}
              className={`${styles.levelDot} ${index <= currentLevelIndex ? styles.active : ''}`}
            />
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.fullScreenContainer}>
      {dominantColor && <BackgroundCanvas dominantColor={dominantColor} />}
      <div className={styles.dashboard}>
        <div 
          className={`${styles.leftPanel} ${leftPanelExpanded ? styles.expanded : ''}`}
          onClick={handlePanelClick}
          onTouchStart={handlePanelTouch}
          onTouchMove={handlePanelTouchMove}
          onTouchEnd={handlePanelTouchEnd}
        >
          <div className={styles.dragHandle} />
          <div className={`${styles.topButtons} ${leftPanelExpanded ? styles.hidden : ''}`}>
            <button className={styles.topButton} onClick={(event) => { onAudienceFinder(); handleButtonClick(event); }}>
              <Users size={24} />
              <span>Audience</span>
            </button>
            <button className={styles.topButton} onClick={(event) => { onShowVenueGraph(); handleButtonClick(event); }}>
              <Map size={24} />
              <span>Venues</span>
            </button>
          </div>
          
          {spotifyData?.pfp && !isMobile && (
            <div className={styles.artistImage}>
              <img src={spotifyData.pfp} alt={artistData.name} />
            </div>
          )}
          
          <div className={`${styles.topButtons} ${leftPanelExpanded ? styles.hidden : ''}`}>
            <button className={styles.topButton} onClick={(event) => { onAudienceFinder(); handleButtonClick(event); }}>
              <Users size={20} />
            </button>
            <button className={styles.topButton} onClick={(event) => { onShowVenueGraph(); handleButtonClick(event); }}>
              <Map size={20} />
            </button>
          </div>
          <div className={styles.leftPanelIndicator}>
            <MoreHorizontal size={24} />
          </div>

          {isMobile && spotifyData?.pfp && (
            <div className={styles.mobileArtistImage}>
              <img src={spotifyData.pfp} alt={artistData.name} />
            </div>
          )}

          <h1>{artistData.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</h1>
          <p><MapPin size={16}/>  {Array.isArray(artistData.location) ? artistData.location.join(', ') : artistData.location}</p>
          
          <p 
            onClick={handleBioClick}
            className={isDescriptionExpanded ? styles.expandedBio : styles.truncatedBio}
          >
            {isDescriptionExpanded ? artistData.bio : truncateDescription(artistData.bio)}
          </p>


          <h2>Genres</h2>  
          <div className={styles.tagContainer}>
            {artistData.genre.map((genre, index) => (
              <span key={index} className={styles.tag}>{genre}</span>
            ))}
          </div>

          <h2>Similar Artists</h2>
          <div className={styles.tagContainer}>
            {artistData.influences.map((influence, index) => (
              <span key={index} className={styles.tag}>{influence}</span>
            ))}
          </div>
          <div className={styles.musicStatsContainer}>
            <h2>Music Stats</h2>
            <div className={styles.musicStats}>
              <div className={styles.statCard}>
                <h3>Spotify Followers</h3>
                <p>{new Intl.NumberFormat().format(followers)}</p>
              </div>
              <PopularityIndicator score={popularity} />
            </div>
          </div>

          <div className={styles.buttonContainer}>
            <button onClick={handleShare}><Share size={16} /> Share Profile</button>
            <button onClick={onShowVenueGraph}><Map size={16} /> Venue Finder</button>
            <button onClick={onAudienceFinder}><Users size={16} /> Discover Audiences</button>
            <button onClick={onBackToSearch}><House size={16} /> Home</button>
          </div>
        </div>
        <div className={`${styles.rightContent} ${leftPanelExpanded ? styles.leftPanelExpanded : ''}`}>
          {renderRightContent()}
        </div>
      </div>
    </div>
  );
}

export default ArtistDashboard;