import React, { useContext, useEffect, useState } from 'react';
import { UserCircle, LogOut } from 'lucide-react';
import { UserContext } from '../../contexts/UserContext';
import { useNavigate } from 'react-router-dom';
import styles from './UserProfile.module.css';

const UserProfile = ({ onSignOut }) => {
  const { user, logout, profilePicture } = useContext(UserContext);
  const navigate = useNavigate();
  const [currentProfilePicture, setCurrentProfilePicture] = useState(profilePicture);

  useEffect(() => {
    setCurrentProfilePicture(profilePicture || user?.profileImage || user?.profile_image);
  }, [profilePicture, user]);

  const handleSignOut = () => {
    if (onSignOut) {
      onSignOut(); 
    }
    
    logout();
    
  };

  return (
    <div className={styles.profile}>
      <div className={styles.profile__info}>
        <span className={styles.profile__name}>{user?.name}</span>
        {currentProfilePicture ? (
          <img 
            src={currentProfilePicture} 
            alt={user?.name}
            className={styles.profile__picture} 
            referrerPolicy="no-referrer"
          />
        ) : (
          <UserCircle className={styles.profile__icon} />
        )}
      </div>
      <button 
        onClick={handleSignOut} 
        className={styles.profile__logout}
        title="Sign out"
      >
        <LogOut size={16} />
      </button>
    </div>
  );
};

export default UserProfile;