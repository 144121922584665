class TrieNode {
  constructor() {
    this.children = {};
    this.isEndOfWord = false;
    this.word = '';
    this.weight = 0;
  }
}

export class TrieSearch {
  constructor() {
    this.root = new TrieNode();
    this.cache = new Map();
  }

  insert(word, weight = 0) {
    let node = this.root;
    const lowerWord = word.toLowerCase();
    
    for (const char of lowerWord) {
      if (!node.children[char]) {
        node.children[char] = new TrieNode();
      }
      node = node.children[char];
    }
    
    node.isEndOfWord = true;
    node.word = word;
    node.weight = weight;
  }

  search(prefix, limit = 10) {
    const cacheKey = `${prefix}-${limit}`;
    if (this.cache.has(cacheKey)) {
      return this.cache.get(cacheKey);
    }

    const results = [];
    const lowerPrefix = prefix.toLowerCase();
    let node = this.root;


    for (const char of lowerPrefix) {
      if (!node.children[char]) {
        this.cache.set(cacheKey, results);
        return results;
      }
      node = node.children[char];
    }

    this._findAllWords(node, results, limit);
    results.sort((a, b) => b.weight - a.weight);
    
    this.cache.set(cacheKey, results);
    return results;
  }

  _findAllWords(node, results, limit) {
    if (results.length >= limit) return;
    
    if (node.isEndOfWord) {
      results.push({
        word: node.word,
        weight: node.weight
      });
    }

    for (const char in node.children) {
      this._findAllWords(node.children[char], results, limit);
    }
  }

  clear() {
    this.root = new TrieNode();
    this.cache.clear();
  }
}
