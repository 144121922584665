import React, { useState, useEffect, useCallback, useMemo, useRef, useContext } from 'react';
import ReactDOM from 'react-dom';
import { UserContext } from '../../contexts/UserContext';
import styles from './IntroAnimation.module.css'; // Change this line to import as styles
const COLORS = {
  nodes: [
    { color: 'rgba(255,99,71,0.9)', glow: 'rgba(255,99,71,0.4)' },    // Tomato
    { color: 'rgba(46,204,113,0.9)', glow: 'rgba(46,204,113,0.4)' },  // Emerald
    { color: 'rgba(155,89,182,0.9)', glow: 'rgba(155,89,182,0.4)' },  // Amethyst
    { color: 'rgba(52,152,219,0.9)', glow: 'rgba(52,152,219,0.4)' },  // Blue
    { color: 'rgba(241,196,15,0.9)', glow: 'rgba(241,196,15,0.4)' },  // Yellow
    { color: 'rgba(230,126,34,0.9)', glow: 'rgba(230,126,34,0.4)' },  // Orange
    { color: 'rgba(231,76,60,0.9)', glow: 'rgba(231,76,60,0.4)' },    // Red
    { color: 'rgba(22,160,133,0.9)', glow: 'rgba(22,160,133,0.4)' }   // Teal
  ],
  edges: {
    primary: 'rgba(255,255,255,0.15)',
    glow: 'rgba(255,255,255,0.05)'
  }
};

const NetworkNode = ({ position, colorSet, delay, size, visible, isActive }) => (
  <div 
    className={`${styles['network-node']} ${visible ? styles.visible : ''} ${isActive ? styles.active : ''}`}
    style={{
      position: 'absolute',
      left: `${position.x}px`,
      top: `${position.y}px`,
      '--color': colorSet.color,
      '--glow-color': colorSet.glow,
      '--delay': `${delay}s`,
      '--size': `${size}px`
    }}
  >
    <div className={styles['node-core']}>
      <div className={styles['node-pulse']} />
      <div className={styles['node-halo']} />
      <div className={styles['node-glow']} />
    </div>
  </div>
);

const NetworkEdge = ({ start, end, colorSet, delay, visible }) => {
  const dx = end.x - start.x;
  const dy = end.y - start.y;
  const angle = Math.atan2(dy, dx) * 180 / Math.PI;
  const length = Math.sqrt(dx * dx + dy * dy);

  return (
    <div 
      className={`${styles['network-edge']} ${visible ? styles.visible : ''}`}
      style={{
        position: 'absolute',
        left: `${start.x}px`,
        top: `${start.y}px`,
        width: `${length}px`,
        transform: `rotate(${angle}deg)`,
        '--color': colorSet.color,
        '--glow-color': colorSet.glow,
        '--delay': `${delay}s`
      }}
    >
      <div className={styles['edge-line']} />
    </div>
  );
};



const SOUND_URL = process.env.PUBLIC_URL + '/sounds/opening_sound.wav';

const AUDIO_ENERGY_THRESHOLDS = {
  NETWORK: 50,  
  BLOOM1: 120, 
  BLOOM2: 100, 
  BLOOM3: 140  
};

const TIMINGS = {
  network: 1000,
  bloom1: 3000,
  bloom2: 6000,
  bloom3: 9000,
  final: 11000
};

const calculateFeatureTimings = (totalDuration) => {

  const animationDuration = totalDuration - 1;
  
  return {
    network: animationDuration * 0.15,
    bloom1: animationDuration * 0.35,
    bloom2: animationDuration * 0.65,
    bloom3: animationDuration * 0.85,
    final: animationDuration
  };
};

const IntroAnimation = ({ onComplete }) => {
  const { hasSeenIntro, setHasSeenIntro } = useContext(UserContext);
  const [powerUpState, setPowerUpState] = useState('initial');
  const audioCtx = useRef(null);
  const audioInitialized = useRef(false);
  const [isLoading, setIsLoading] = useState(true);
  const analyserRef = useRef(null);
  const audioDataRef = useRef(new Uint8Array(0));
  const [audioStartTime, setAudioStartTime] = useState(null);
  const gainNodeRef = useRef(null);
  const [phase, setPhase] = useState('initial');
  const [activeNodes, setActiveNodes] = useState(new Set());
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [isAudioClosed, setIsAudioClosed] = useState(false);


  useEffect(() => {
    if (hasSeenIntro) {
      onComplete();
    }
  }, [hasSeenIntro, onComplete]);

  const initAudioContext = useCallback(() => {
    if (!audioInitialized.current) {
      audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
      audioInitialized.current = true;
    }
    return audioCtx.current;
  }, []);

  const setupAudioAnalyser = useCallback(async () => {
    try {
      if (!audioCtx.current) {
        initAudioContext();
      }

      const response = await fetch(SOUND_URL, {
        headers: {
          'Accept': 'audio/wav',
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const arrayBuffer = await response.arrayBuffer();


      const audioBuffer = await audioCtx.current.decodeAudioData(arrayBuffer);


      const gainNode = audioCtx.current.createGain();
      gainNode.gain.setValueAtTime(0.0001, audioCtx.current.currentTime);
      gainNodeRef.current = gainNode;


      const analyser = audioCtx.current.createAnalyser();
      analyser.fftSize = 512; 
      analyser.smoothingTimeConstant = 0.85; 
      analyserRef.current = analyser;
      
      const source = audioCtx.current.createBufferSource();
      source.buffer = audioBuffer;
      
  
      source.connect(analyser);
      analyser.connect(gainNode);
      gainNode.connect(audioCtx.current.destination);
      

      audioDataRef.current = new Uint8Array(analyser.frequencyBinCount);
      
 
      source.start(0);
      setAudioStartTime(audioCtx.current.currentTime);
      
 
      gainNode.gain.linearRampToValueAtTime(
        0.15,
        audioCtx.current.currentTime + 3.0
      );
      
      return audioBuffer.duration;
    } catch (error) {

      console.error('Audio setup failed:', error);
      return 0;
    }
  }, [initAudioContext]);

  const getAudioEnergy = useCallback(() => {
    if (!analyserRef.current) return 0;
    
    analyserRef.current.getByteFrequencyData(audioDataRef.current);
    const sum = audioDataRef.current.reduce((acc, val) => acc + val, 0);
    return sum / audioDataRef.current.length;
  }, []);

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const generateNodes = useCallback(() => {
    const { width, height } = dimensions;
    const nodes = [];
    

    const centerX = width / 2;
    const centerY = height / 2;
    const maxRadius = Math.min(width, height) * 0.65;
    

    const scaleFactor = Math.min(width, height) / 1000;
    const baseSizes = {
      center: Math.max(80 * scaleFactor, 20),
      inner: Math.max(70 * scaleFactor, 15),
      middle: Math.max(60 * scaleFactor, 12),
      outer: Math.max(50 * scaleFactor, 10)
    };


    nodes.push({
      id: 'center',
      position: {
        x: centerX,
        y: centerY
      },
      colorSet: COLORS.nodes[0],
      size: baseSizes.center,
      delay: 0
    });


    const ringConfigs = [
      { count: 6, radiusFactor: 0.3, size: baseSizes.inner },
      { count: 12, radiusFactor: 0.6, size: baseSizes.middle },
      { count: 18, radiusFactor: 0.9, size: baseSizes.outer }
    ];

    ringConfigs.forEach((ring, ringIndex) => {
      const baseRadius = maxRadius * ring.radiusFactor;
      
      for (let i = 0; i < ring.count; i++) {
        const angle = (i / ring.count) * Math.PI * 2;
        const radiusVariance = (Math.random() - 0.5) * (maxRadius * 0.1);
        const finalRadius = baseRadius + radiusVariance;
        
        const x = centerX + Math.cos(angle) * finalRadius;
        const y = centerY + Math.sin(angle) * finalRadius;

        nodes.push({
          id: `ring-${ringIndex}-${i}`,
          position: { x, y },
          colorSet: COLORS.nodes[i % COLORS.nodes.length],
          size: ring.size,
          delay: 0.2 + (ringIndex * 0.2) + (i * 0.1)
        });
      }
    });

    return nodes;
  }, [dimensions]);

  const nodes = useMemo(() => generateNodes(), [generateNodes]);


  const edges = useMemo(() => {
    const connections = [];
    const connectNodes = (node1, node2, prob = 1) => {
      if (Math.random() < prob) {
        connections.push({
          start: node1.position,
          end: node2.position,
          colorSet: node1.colorSet,
          delay: Math.max(node1.delay, node2.delay) - 0.1
        });
      }
    };
  
    nodes.forEach((node, i) => {
      if (i === 0) return; 
      
      const centerDist = Math.hypot(
        nodes[0].position.x - node.position.x,
        nodes[0].position.y - node.position.y
      );
      

      let closestNode = nodes[0];
      let minDist = centerDist;
      
      nodes.forEach((otherNode, j) => {
        if (i !== j) {
          const dist = Math.hypot(
            otherNode.position.x - node.position.x,
            otherNode.position.y - node.position.y
          );
          if (dist < minDist) {
            minDist = dist;
            closestNode = otherNode;
          }
        }
      });
      

      connectNodes(nodes[0], node, 0.7);
      if (closestNode !== nodes[0]) {
        connectNodes(closestNode, node, 1);
      }
    });
  
  
    nodes.forEach((node1, i) => {
      nodes.slice(i + 1).forEach(node2 => {
        const dist = Math.hypot(
          node1.position.x - node2.position.x,
          node1.position.y - node2.position.y
        );
        if (dist < 30) {
          connectNodes(node1, node2, 0.4);
        }
      });
    });
  
    return connections;
  }, [nodes]);

  
  const startSilentAnimation = useCallback(() => {
    const runSequence = async () => {

      setPhase('network');
      await new Promise(r => setTimeout(r, TIMINGS.network));
      

      setPhase('bloom1');
      await new Promise(r => setTimeout(r, TIMINGS.bloom1 - TIMINGS.network));
      
      setPhase('bloom2');
      await new Promise(r => setTimeout(r, TIMINGS.bloom2 - TIMINGS.bloom1));
      
      setPhase('bloom3');
      await new Promise(r => setTimeout(r, TIMINGS.bloom3 - TIMINGS.bloom2));
      
      await new Promise(r => setTimeout(r, TIMINGS.final - TIMINGS.bloom3));
      onComplete();
    };

    runSequence();
  }, [onComplete]);

  const closeAudioContext = useCallback(() => {
    if (audioCtx.current && audioCtx.current.state !== 'closed') {
      try {
        audioCtx.current.close();
      } catch (error) {
      }
    }
  }, []);

  const handleComplete = useCallback(() => {
    closeAudioContext();
    onComplete();
  }, [closeAudioContext, onComplete]);

  const startAnimationSequence = useCallback((duration) => {
    const timings = calculateFeatureTimings(duration);
    

    const schedulePhase = (phase, delay) => {
      setTimeout(() => setPhase(phase), delay * 1000);
    };

    schedulePhase('network', 0);
    schedulePhase('bloom1', timings.network);
    schedulePhase('bloom2', timings.bloom1);
    schedulePhase('bloom3', timings.bloom2);
    

    setTimeout(handleComplete, duration * 1000);
  }, [handleComplete]);

  const handleMusicActivation = useCallback((e) => {
    if (powerUpState !== 'initial') return;
    
    setPowerUpState('powering');
    setPhase('network'); 
    
  
    if (!audioCtx.current || audioCtx.current.state === 'closed') {
      audioCtx.current = new (window.AudioContext || window.webkitAudioContext)();
    }
    
    Promise.all([
      audioCtx.current.resume(),
      setupAudioAnalyser()
    ]).then(([, duration]) => {
      setPowerUpState('powered');
      startAnimationSequence(duration || 10); 
    }).catch(error => {
      console.warn('Failed to start audio:', error);
      setPowerUpState('powered');
      startAnimationSequence(10); 
    });
  }, [powerUpState, setupAudioAnalyser, startAnimationSequence]);


 
  useEffect(() => {
    if (powerUpState !== 'powered') return;


    const activateRandomNodes = () => {
      if (phase === 'initial') return;
      
      setActiveNodes(prev => {
        const next = new Set(prev);
        const randomNode = nodes[Math.floor(Math.random() * nodes.length)];
        next.add(randomNode.id);
        setTimeout(() => {
          setActiveNodes(current => {
            const updated = new Set(current);
            updated.delete(randomNode.id);
            return updated;
          });
        }, 1000);
        return next;
      });
    };

    const interval = setInterval(activateRandomNodes, 2000);
    return () => clearInterval(interval);
  }, [powerUpState, phase, nodes]);

  
  useEffect(() => {
    setIsLoading(true);
  }, []);

  
  useEffect(() => {
    return () => {
      closeAudioContext();
    };
  }, [closeAudioContext]);

  return (
    <>
      <div className={`${styles['intro-overlay']} ${styles[`phase-${phase}`]}`} />
      <div className={`${styles['intro-fullscreen']} ${styles[`phase-${phase}`]}`}>
        {powerUpState === 'initial' && (
          <div 
            className={styles['music-orb-container']}
            role="button"
            tabIndex={0}
            onClick={handleMusicActivation}
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleMusicActivation(e);
              }
            }}
          >
            <div className={styles['music-orb']}>
              <div className={styles['music-orb-inner']}>
                <div className={styles['music-icon']}>♪</div>
                <div className={styles['music-orb-text']}>Release the Music</div>
              </div>
              <div className={styles['music-orb-rings']}></div>
            </div>
          </div>
        )}
        {powerUpState !== 'initial' && (
          <>
            <div className={styles['network-container']}>
              {edges.map((edge, i) => (
                <NetworkEdge
                  key={`edge-${i}`}
                  {...edge}
                  visible={phase !== 'initial'}
                />
              ))}
              {nodes.map((node) => (
                <NetworkNode
                  key={node.id}
                  {...node}
                  visible={phase !== 'initial'}
                  isActive={activeNodes.has(node.id)}
                />
              ))}
            </div>
            <div className={styles['intro-text']}>
              <div className={styles['feature-list']}>
                <span className={styles.feature}>💃 Match with Your Perfect Venues</span>
                <span className={styles.feature}>🧐 Find your next audience</span>
                <span className={styles.feature}>🚀 See key artists profiles</span>
                <span className={`${styles.feature} ${styles['final-feature']}`}>✨ All on one app</span>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default IntroAnimation;