import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Sparkles, ArrowLeft, Music, UserCog, Megaphone, LogIn, Check, X, } from 'lucide-react';
import styles from './login.module.css';
import Synspire_logo from '../../images/Synspire_logo.png';
import { UserContext } from '../../contexts/UserContext';

const Login = ({ onLogin, onTryMode, initialView = 'initial', userData = null }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { initiateSpotifyAuth } = useContext(UserContext);
  const [view, setView] = useState(initialView); // 'initial', 'login', 'register', or 'try'
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [artistProfileUrl, setArtistProfileUrl] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [registrationStep, setRegistrationStep] = useState(1); 
  const [user, setUser] = useState(null);


  const sampleArtists = [
    'yijia tu',
    'Stornoway',
    'Simeon Hammond Dallas',
    'Alabama 3',
    'Victoria Georgieva'
  ];

  const roles = [
    {
      id: 'artist',
      title: 'Artist',
      description: 'I make music and perform',
      icon: Music
    },
    {
      id: 'manager',
      title: 'Manager',
      description: 'I represent artists',
      icon: UserCog
    },
    {
      id: 'promoter',
      title: 'Promoter',
      description: 'I organize events and book artists',
      icon: Megaphone
    }
  ];

  const handleTryArtist = (artistName) => {
    onTryMode(artistName);
  };

  const handleContinueWithSpotify = () => {
    if (selectedRoles.length === 0) {
      setError('Please select at least one role');
      return;
    }


    if (selectedRoles.includes('artist')) {
      if (!artistProfileUrl) {
        setError('Please enter your Spotify artist profile URL');
        return;
      }

      if (!artistProfileUrl.startsWith('https://open.spotify.com/artist/')) {
        setError('Please enter a valid Spotify artist profile URL');
        return;
      }
    }


    setError('');


    localStorage.setItem('pending_roles', JSON.stringify(selectedRoles));
    if (artistProfileUrl) {
      localStorage.setItem('pending_artist_url', artistProfileUrl);
    }

    initiateSpotifyAuth(selectedRoles, artistProfileUrl);
  };

  const handleRoleToggle = (roleId) => {
    setSelectedRoles(prev => {
      if (prev.includes(roleId)) {
        return prev.filter(id => id !== roleId);
      }
      return [...prev, roleId];
    });
    setError(''); 
  };

  useEffect(() => {
    const existingToken = localStorage.getItem('token');
    const existingUser = localStorage.getItem('userData');
    
    if (existingToken && existingUser) {
      try {
        const userData = JSON.parse(existingUser);
        setUser(userData);
        setView('welcome');
      } catch (error) {
        console.error('Error parsing stored user data:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
      }
    }
  }, []);

  const handleSpotifyLogin = async () => {
    try {
      setLoading(true);
      initiateSpotifyAuth();
    } catch (error) {
      console.error('Spotify login error:', error);
      setError('Failed to connect to Spotify');
    } finally {
      setLoading(false);
    }
  };

  const renderInitialView = () => (
    <div className={`${styles.login__view} ${styles.login__view_initial}`}>
      <div className={styles.login__hero}>
        <h1 className={styles.login__welcome}>
          Find your next <span className={styles.login__highlight}>breakthrough</span>
        </h1>
        <p className={styles.login__tagline}>
          Connect with venues, promoters, and fellow artists in your local music scene
        </p>
      </div>

      <div className={styles.login__cta_container}>
        <button 
          className={`${styles.login__button_main} ${styles.login__button_explore}`}
          onClick={() => setView('try')}
        >
          <div className={styles.login__button_content}>
            <div className={styles.login__button_main_text}>
              <Sparkles className={styles.login__button_icon} />
              Explore Without Account
            </div>
          </div>
        </button>
        
        <a 
          href="/stay-tuned"
          className={`${styles.login__button_main} ${styles.login__button_beta}`}
        >
          <div className={styles.login__button_content}>
            <div className={styles.login__button_main_text}>
              <Sparkles className={styles.login__button_icon} />
              <span className={styles.login__button_text_desktop}>Join the Beta Program</span>
              <span className={styles.login__button_text_mobile}>Join Beta</span>
            </div>
          </div>
        </a>

        {/* Spotify Login Button temporarily removed
        <button 
          className={`${styles.login__button_main} ${styles.login__button_auth}`}
          onClick={handleSpotifyLogin}
          disabled={loading}
        >
          <div className={styles.login__button_content}>
            <div className={styles.login__button_main_text}>
              <LogIn className={styles.login__button_icon} />
              Login with Spotify
            </div>
          </div>
        </button> 
        */}
      </div>

      {error && (
        <div className={styles.login__error}>
          {error}
        </div>
      )}
    </div>
  );

  const renderTryView = () => (
    <div className={`${styles.login__view} ${styles.login__view_try} ${styles.login__view_compact}`}>
      <button
        onClick={() => setView('initial')}
        className={styles.login__back_button}
      >
        <ArrowLeft size={20} />
        Back
      </button>
      <h2 className={styles.login__title}>Try Synspire</h2>
      <p className={styles.login__subtitle}>Explore these artist profiles:</p>
      <div className={`${styles.login__trial_container} ${styles.login__trial_container_compact}`}>
        {sampleArtists.map((artist) => (
          <button
            key={artist}
            className={styles.login__trial_artist}
            onClick={() => handleTryArtist(artist)}
          >
            {artist}
          </button>
        ))}
      </div>
    </div>
  );

  const renderRoleSelection = () => (
    <div className={`${styles.login__view} ${styles.login__view_roles}`}>
      <button
        onClick={() => setView('initial')}
        className={styles.login__back_button}
      >
        <ArrowLeft size={20} />
        Back
      </button>

      <h2 className={styles.login__title}>Choose Your Roles</h2>
      <p className={styles.login__subtitle}>
        Select all roles that apply to you
      </p>

      {selectedRoles.length > 0 && (
        <div className={styles.login__selected_roles}>
          <div className={styles.login__role_tags}>
            {selectedRoles.map(roleId => {
              const role = roles.find(r => r.id === roleId);
              const Icon = role.icon;
              return (
                <span key={roleId} className={styles.login__role_tag}>
                  <Icon size={16} />
                  {role.title}
                  <button
                    className={styles.login__role_tag_remove}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRoleToggle(roleId);
                    }}
                  >
                    <X size={14} />
                  </button>
                </span>
              );
            })}
          </div>
        </div>
      )}

      <div className={styles.login__roles_grid}>
        {roles.map((role) => {
          const Icon = role.icon;
          const isSelected = selectedRoles.includes(role.id);
          return (
            <button
              key={role.id}
              className={`${styles.login__role_card} ${
                isSelected ? styles.login__role_card_selected : ''
              }`}
              onClick={() => handleRoleToggle(role.id)}
            >
              <div className={styles.login__role_icon_wrapper}>
                <Icon size={32} className={styles.login__role_icon} />
              </div>
              <h3>{role.title}</h3>
              <p>{role.description}</p>
              <div className={`${styles.login__role_checkmark} ${
                isSelected ? styles.login__role_checkmark_active : ''
              }`}>
                <Check size={20} />
              </div>
            </button>
          );
        })}
      </div>

      {selectedRoles.includes('artist') && (
        <div className={styles.login__input_wrapper}>
          <input
            type="url"
            placeholder="Your Spotify Artist Profile URL"
            className={styles.login__input}
            value={artistProfileUrl}
            onChange={(e) => setArtistProfileUrl(e.target.value)}
            required
          />
          <Music className={styles.login__input_icon} />
        </div>
      )}

      {error && <span className={styles.login__error}>{error}</span>}

      <div className={styles.login__action_container}>
        <button
          onClick={handleContinueWithSpotify}
          className={`${styles.login__button_primary} ${styles.login__button_spotify}`}
          disabled={selectedRoles.length === 0}
        >
          Continue with Spotify
        </button>
      </div>
    </div>
  );

  useEffect(() => {
    if (view !== 'register') {
      setSelectedRoles([]);
      setRegistrationStep(1);
    }
  }, [view]);

  
  useEffect(() => {
    if (view === 'initial') {
      setSelectedRoles([]);
      setRegistrationStep(1);
      setError('');
    }
  }, [view]);

  useEffect(() => {
    if (initialView !== 'initial') {
      setView(initialView);
      if (initialView === 'welcome' && userData) {
        setUser(userData);
      }
    }
  }, [initialView, userData]);

  const getRedirectMessage = (userData) => {
    if (userData.roles.includes('artist')) {
      return "Redirecting to your artist profile...";
    }
    return "Redirecting to search...";
  };

  const handleSuccessfulLogin = async (userData) => {
    const updatedUserData = {
      ...userData,
      profileImage: userData.profile_image || userData.profileImage || userData.images?.[0]?.url,
    };
    setUser(updatedUserData);
    setView('welcome');


    setTimeout(() => {
      onLogin(updatedUserData);
    }, 1000); 
  };

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const accessToken = params.get('access_token');
    
    if (accessToken) {
      const userData = {
        email: decodeURIComponent(params.get('email')),
        name: params.get('display_name'),
        accessToken,
        spotify_id: params.get('id'),
        roles: JSON.parse(params.get('roles') || '["user"]'),
        profile_image: params.get('profile_image'),
        profileImage: params.get('profile_image'),
        images: params.get('profile_image') ? [{ url: params.get('profile_image') }] : []
      };
      
      // Always show welcome view first
      handleSuccessfulLogin(userData);
    }
  }, [location]);

  // Move useEffect outside of renderWelcomeView
  useEffect(() => {
    if (view === 'welcome' && user) {
      const timer = setTimeout(() => {
        onLogin(user);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [view, user, onLogin]);

  const renderWelcomeView = () => (
    <div className={`${styles.login__view} ${styles.login__view_welcome}`}>
      <div className={styles.login__hero}>
        <h1 className={styles.login__welcome}>
          Welcome back, <span className={styles.login__highlight}>{user?.name || 'User'}</span>!
        </h1>
        <p className={styles.login__tagline}>
          You've successfully logged in with Spotify
        </p>
      </div>
      <div className={styles.login__cta_container}>
        <div className={styles.login__loading}>
          <p>{getRedirectMessage(user)}</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className={styles.login__page}>
      <div className={styles.login__container}>
        <div className={styles.login__content}>
          <img src={Synspire_logo} alt="Synspire Logo" className={styles.login__logo} />
          <div className={`${styles.login__card} ${styles[`login__card_${view}`]}`}>
            {view === 'initial' && renderInitialView()}
            {view === 'try' && renderTryView()}
            {view === 'roles' && renderRoleSelection()}
            {view === 'welcome' && renderWelcomeView()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;