import React, { useEffect } from 'react';
import styles from './ArtistDashboard.module.css';

const BackgroundCanvas = ({ dominantColor }) => {
  const [analogous1, analogous2] = dominantColor?.analogous || [[65, 105, 225], [45, 85, 205]];
  const [triadic1, triadic2] = dominantColor?.triadic || [[225, 65, 105], [105, 225, 65]];

  useEffect(() => {
    const container = document.querySelector(`.${styles.fullScreenContainer}`);
    if (!container) return;

    const existingStyle = document.getElementById('background-animation-style');
    if (existingStyle) {
      existingStyle.remove();
    }

    container.style.background = `
      linear-gradient(
        135deg,
        rgb(${analogous1[0]}, ${analogous1[1]}, ${analogous1[2]}) 0%,
        rgb(${analogous2[0]}, ${analogous2[1]}, ${analogous2[2]}) 100%
      )
    `;

    const style = document.createElement('style');
    style.id = 'background-animation-style';
    style.textContent = `
      .${styles.fullScreenContainer}::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
          45deg,
          rgba(${triadic1[0]}, ${triadic1[1]}, ${triadic1[2]}, 0.05) 0%,
          rgba(${triadic2[0]}, ${triadic2[1]}, ${triadic2[2]}, 0.05) 100%
        );
        animation: shimmer 45s linear infinite;
        transform: translateZ(0);
        will-change: transform;
        pointer-events: none;
        z-index: 0;
        mix-blend-mode: soft-light;
      }

      .${styles.fullScreenContainer}::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: radial-gradient(
          circle at 50% 50%,
          rgba(${analogous1[0]}, ${analogous1[1]}, ${analogous1[2]}, 0.1) 0%,
          transparent 70%
        );
        pointer-events: none;
        z-index: 1;
      }

      @keyframes shimmer {
        0% {
          transform: translate(0, 0) rotate(0deg);
        }
        100% {
          transform: translate(0, 0) rotate(360deg);
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      if (style && style.parentNode) {
        style.parentNode.removeChild(style);
      }
      if (container) {
        container.style.background = '';
      }
    };
  }, [dominantColor, analogous1, analogous2, triadic1, triadic2]);

  return null;
};

export default BackgroundCanvas;
