import React, { createContext, useState, useContext } from 'react';

const TrialContext = createContext(null);

export const TrialProvider = ({ children }) => {
  const [trialArtist, setTrialArtist] = useState(null);
  const [isTrialMode, setIsTrialMode] = useState(false);

  const startTrial = (artist) => {
    setTrialArtist(artist);
    setIsTrialMode(true);
  };

  const endTrial = () => {
    setTrialArtist(null);
    setIsTrialMode(false);
  };

  return (
    <TrialContext.Provider value={{ trialArtist, isTrialMode, startTrial, endTrial }}>
      {children}
    </TrialContext.Provider>
  );
};

export const useTrial = () => useContext(TrialContext);
