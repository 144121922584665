import React from 'react';
import { X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useTrial } from '../../contexts/TrialContext';
import styles from './TrialBar.module.css';

const TrialBar = ({ onExitTrial, currentArtist }) => {
  const navigate = useNavigate();
  const { endTrial } = useTrial();

  const formatArtistName = (name) => {
    if (!name) return 'Artist';
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  /* Just temporarily redirecting to the login page for now
  const handleSignUp = () => {
    endTrial();
    onExitTrial();
    navigate('/login', { 
      replace: true,
      state: { 
        view: 'register',
        resetAuth: true
      }
    });
  };
  */


  const handleSignUp = () => {
    endTrial();
    onExitTrial();
    navigate('/stay-tuned', { 
      replace: true
    });
  }

  return (
    <div className={styles.trialBar}>
      <div className={styles.trialContent}>
        <span className={styles.trialText}>
          Viewing {formatArtistName(currentArtist)} in Trial Mode
        </span>
        <button onClick={handleSignUp} className={styles.signUpButton}>
          Join The for Beta
        </button>
      </div>
    </div>
  );
};

export default TrialBar;
