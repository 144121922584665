import axios from 'axios';
import { getObfuscatedKeyWithTimestamp } from '../../services/policy_delta';

export const fetchSpotifyData = async (spotifyId) => {
  if (!spotifyId) {
    console.warn('No Spotify ID provided');
    return null;
  }
  
  try {
    const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
    
    const apiUrl = `${process.env.REACT_APP_API}/artist/spotify?artist_id=${encodeURIComponent(spotifyId)}`;

    const response = await axios.get(
      apiUrl,
      {
        headers: {
          'API-Key': obfuscatedKey,
          'timestamp': timestamp,
        }
      }
    );

    const data = response.data;

    const transformedData = {
      pfp: data.metadata.images?.[0]?.url || null,
      followers: data.metadata.followers?.total || 0,
      popularity: data.metadata.popularity || 0,
      genres: data.metadata.genres || [],
      topTracks: data.top_tracks?.tracks?.map(track => ({
        name: track.name,
        album: {
          name: track.album.album_type === 'single' && track.album.name === track.name 
            ? 'Single' 
            : track.album.name,
          release_date: track.album.release_date,
          release_date_precision: track.album.release_date_precision
        },
        popularity: track.popularity,
        previewUrl: track.preview_url,
        external_urls: track.external_urls
      })) || []
    };

    return transformedData;

  } catch (error) {
    console.error('Error fetching Spotify data:', {
      message: error.message,
      status: error.response?.status,
      statusText: error.response?.statusText,
      responseData: error.response?.data
    });
    return null;
  }
};
