import React, { useState } from 'react';
import { Send, ArrowLeft } from 'lucide-react';
import styles from './staytuned.module.css';
import Synspire_logo from '../../images/Synspire_logo.png';
import axios from 'axios';
import { getObfuscatedKeyWithTimestamp } from '../../services/policy_delta';

const StayTuned = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('initial'); // initial, loading, success, error
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');

    try {
      const { obfuscatedKey, timestamp } = getObfuscatedKeyWithTimestamp();
      await axios.post(
        `${process.env.REACT_APP_API}/beta/signup`,
        { email },
        {
          headers: {
            'Content-Type': 'application/json',
            'API-Key': obfuscatedKey,
            'timestamp': timestamp,
          }
        }
      );
      setStatus('success');
    } catch (error) {
      setStatus('error');
      setErrorMessage(error.response?.data?.error || 'Something went wrong. Please try again.');
    }
  };

  return (
    <div className={styles.staytuned__page}>
      <div className={styles.staytuned__container}>
        <div className={styles.staytuned__content}>
          <img src={Synspire_logo} alt="Synspire Logo" className={styles.staytuned__logo} />
          <div className={styles.staytuned__card}>
            <a href="/" className={styles.staytuned__back}>
              <ArrowLeft size={20} />
              Back
            </a>
            
            <h1 className={styles.staytuned__title}>
              Stay in the <span className={styles.staytuned__highlight}>Loop</span>
            </h1>
            
            <p className={styles.staytuned__subtitle}>
              Be the first to know when we launch new features and updates
            </p>

            {status === 'success' ? (
              <div className={styles.staytuned__success}>
                <div className={styles.staytuned__success_icon}>✨</div>
                <h2>Thank you for subscribing!</h2>
                <p>We'll keep you posted on our latest updates.</p>
              </div>
            ) : (
              <form onSubmit={handleSubmit} className={styles.staytuned__form}>
                <div className={styles.staytuned__input_wrapper}>
                  <input
                    type="email"
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={styles.staytuned__input}
                    required
                  />
                  <button 
                    type="submit" 
                    className={styles.staytuned__submit}
                    disabled={status === 'loading'}
                  >
                    {status === 'loading' ? (
                      <div className={styles.staytuned__loader} />
                    ) : (
                      <Send size={20} />
                    )}
                  </button>
                </div>
                {status === 'error' && (
                  <div className={styles.staytuned__error}>
                    {errorMessage}
                  </div>
                )}
              </form>
            )}

            <div className={styles.staytuned__features}>
              <div className={styles.staytuned__feature}>
                <span className={styles.staytuned__feature_icon}>🎵</span>
                <span>Early access to new features</span>
              </div>
              <div className={styles.staytuned__feature}>
                <span className={styles.staytuned__feature_icon}>📊</span>
                <span>Industry insights & updates</span>
              </div>
              <div className={styles.staytuned__feature}>
                <span className={styles.staytuned__feature_icon}>🌟</span>
                <span>Exclusive beta testing opportunities</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StayTuned;
